<app-loading [ngClass]="{'active' : loader == true}"></app-loading>
<div class="main-content">
    <div class="page-content">
        <div class="container-fluid pt-0 pt-md-0 pt-lg-0">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-block d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-0 font-size-15" [ngClass]="isDarkMode ? 'text-white' : 'text-dark'">
                            <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Consentimiento informado</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Consentiment informat</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Informed consent</span>
                        </h4>
              
                        <div class="page-title-right mt-2 mt-sm-0">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Información personal</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Informació personal</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Personal information</span>
                                </li>
                            </ol>
                        </div>
              
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="paso_cliente" *ngIf="pasoCliente">
                        <form [formGroup]="formCliente" (submit)="editCliente(formCliente.value)">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <h4 *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang" class="card-title mb-4 text-dark"><i class="bx bxs-user-circle"></i> Información personal</h4>
                                            <h4 *ngIf="trabajo?.firma_telematica_lang == 'ca'" class="card-title mb-4 text-dark"><i class="bx bxs-user-circle"></i> Informació personal</h4>
                                            <h4 *ngIf="trabajo?.firma_telematica_lang == 'en'" class="card-title mb-4 text-dark"><i class="bx bxs-user-circle"></i> Personal information</h4>
                                            <div class="alert alert-info fade show mt-3 mb-4" role="alert">
                                                <i class="bx bx-info-circle font-size-17 align-middle mr-1"></i>
                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Los datos personales que introduzcas aquí son los que se mostrarán en el consentimiento informado.</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Les dades personals que introdueixis aquí són les que es mostraran en el consentiment informat.</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">The personal data you enter here will be displayed in the informed consent.</span>
                                            </div>
                                            <div class="row mb-4">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="formrow-nombre-input" class="requerido">
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Nombre</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Nom</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Name</span>
                                                        </label>
                                                        <input type="text" class="form-control" id="formrow-nombre-input" placeholder="Nombre" formControlName="nombre">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="formrow-primer-apellido-input" class="requerido">
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Primer apellido</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Primer cognom</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Surname</span>
                                                        </label>
                                                        <input type="text" class="form-control" id="formrow-primer-apellido-input" placeholder="Primer apellido" formControlName="apellido1">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="formrow-segundo-apellido-input">
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Segundo apellido</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Segon cognom</span>
                                                            <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Second Surname</span>
                                                        </label>
                                                        <input type="text" class="form-control" id="formrow-segundo-apellido-input" placeholder="Segundo apellido" formControlName="apellido2">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="clearfix">
                                                            <label for="formrow-fecha-nacimiento-input" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Fecha de nacimiento</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Data de naixement</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Date of birth</span>
                                                            </label>
                                                            <!--div class="float-right d-none d-md-block">
                                                                <a data-toggle="collapse" href="#info_consentimiento_digital" aria-expanded="false" aria-controls="info_consentimiento_digital"><span class="badge bg-secondary text-light mr-1 p-1"><i class="bx bxs-error-circle align-middle mr-1"></i> <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Información importante sobre la fecha</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Informació important sobre la data</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Important information about the date</span></span></a>
                                                            </div>
                                                            <div class="d-block d-md-none mb-3">
                                                                <a data-toggle="collapse" href="#info_consentimiento_digital" aria-expanded="false" aria-controls="info_consentimiento_digital"><span class="w-100 badge bg-secondary text-light p-2 font-size-12"><i class="bx bxs-error-circle align-middle mr-1"></i> <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Información importante sobre la fecha</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Informació important sobre la data</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Important information about the date</span></span></a>
                                                            </div-->
                                                        </div>
                                                        <!--input class="form-control" type="text" id="fecha_nacimiento" placeholder="AAAA-MM-DD" formControlName="fecha_nacimiento"-->
                                                        <!-- Forma nueva de meter las fechas -->
                                                        <input type="date" formControlName="fecha_nacimiento" class="inner form-control">
                                                        <!--div *ngIf="formCliente.get('fecha_nacimiento').errors && formCliente.get('fecha_nacimiento').touched" class="text-danger">
                                                            <small *ngIf="formCliente.get('fecha_nacimiento').errors.invalidDate"><span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Formato de fecha incorrecto. Debe ser AAAA-MM-DD.</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Format de data incorrecte. Ha de ser AAAA-MM-DD.</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Incorrect date format. It should be YYYY-MM-DD.</span></small>
                                                        </div-->
                                                    </div>
                                                </div>
                                                <!--div class="col-md-12 collapse" id="info_consentimiento_digital">
                                                    <div class="alert alert-info fade show mt-0 mb-3" role="alert">
                                                        <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">La fecha de nacimiento debe introducirse con el siguiente formato: AÑO-MES-DÍA.<br>Por ejemplo, para <i>23 de diciembre de 1980</i>, escribiremos exactamente <strong>1980-12-23</strong>, utilizando guiones tal y como se muestra en el ejemplo. <br>Si tienes dudas, mira <a href="https://youtu.be/eYuaTV6T50o" target="_blank"><u>este video</u></a>.</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">La data de naixement s'ha d'introduir amb el següent format: ANY-MES-DIA.<br>Per exemple, per al <i>23 de desembre de 1980</i>, escriurem exactament <strong>1980-12-23</strong>, utilitzant guions tal com es mostra a l'exemple. <br>Si tens dubtes, mira <a href="https://youtu.be/eYuaTV6T50o" target="_blank"><u>aquest vídeo</u></a>.</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">The date of birth must be entered in the following format: YEAR-MONTH-DAY.<br>For example, for <i>December 23, 1980</i>, we would write exactly <strong>1980-12-23</strong>, using hyphens as shown in the example. <br>If you have any doubts, watch <a href="https://youtu.be/eYuaTV6T50o" target="_blank"><u>this video</u></a>.</span>
                                                    </div>
                                                </div-->
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="clearfix">
                                                            <label for="formrow-dni-pasaporte-input" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">DNI/Pasaporte</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">DNI/Pasaport</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">ID Card</span>
                                                            </label>
                                                        </div>
                                                        <input type="text" class="form-control" id="formrow-dni-pasaporte-input" placeholder="DNI / Pasaporte" formControlName="dni">
                                                    </div>
                                                </div>
                                            
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="clearfix">
                                                            <label for="input-mask" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Teléfono</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Telèfon</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Phone</span>
                                                            </label>
                                                            <div class="float-right">
                                                                <span class="small text-dark">
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Nº de telf. sin prefijo ni espacios.</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Nº de telèfon sense prefix ni espais.</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Phone number without prefix or spaces.</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <input placeholder="644944535" class="form-control" formControlName="telefono" (input)="onTelefonoInputChange($event.target.value)">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <div class="clearfix">
                                                            <label for="formrow-email-input" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Email</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Email</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Email</span>
                                                            </label>
                                                        </div>
                                                        <input type="text" class="form-control" id="formrow-email-input" placeholder="Email" formControlName="email">
                                                    </div>
                                                </div>
                                            </div>
                        
                                            <div>
                                                <h4 class="card-title mb-4 text-dark"><i class="bx bx-map-pin"></i>
                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Dirección</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Adreça</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Address</span>
                                                </h4>
                                            
                                                <div class="row">
                                                    <div class="form-group col-md-3">
                                                        <label for="pais_id" class="col-form-label requerido">{{ 'País' }} 
                                                          <span *ngIf="trabajo?.firma_telematica_lang == 'en'"> (Country)</span>
                                                        </label>
                                                        <select 
                                                          class="form-control" 
                                                          formControlName="pais_id" 
                                                          name="pais_id" 
                                                          (change)="setProvinciasPais($event.target.value)">
                                                          <option value="" selected>{{ 'Elige una opción' }}</option>
                                                          <option *ngFor="let item of paises" [value]="item.id">
                                                            {{ getCountryName(item.nombre) }}
                                                          </option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-3" *ngIf="is_spain">
                                                        <label for="comunidad_id" class="col-form-label requerido">{{ 'Comunidad autónoma'  }}</label>
                                                        <select class="form-control" formControlName="comunidad_id" name="comunidad_id" (change)="setProvincias($event.target.value)"> 
                                                            <option value="" selected>{{ 'Elige una opción'  }}</option>
                                                            <option *ngFor="let item of comunidades" value = "{{item.id}}">{{item.nombre}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                    <label for="provincia_id" class="col-form-label requerido" *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || trabajo?.firma_telematica_lang == 'ca' || !trabajo?.firma_telematica_lang">Provincia</label>
                                                    <label for="provincia_id" class="col-form-label requerido" *ngIf="trabajo?.firma_telematica_lang == 'en'">Province</label>
                                                    <select class="form-control" formControlName="provincia_id" name="provincia_id" (change)="setCiudades($event.target.value)"> 
                                                        <option value="" selected>{{ 'Elige una opción'  }}</option>
                                                        <option *ngFor="let item of provincias" value = "{{item.id}}">{{item.nombre}}</option>
                                                    </select>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                    <label for="localidad_id" class="col-form-label requerido" *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || trabajo?.firma_telematica_lang == 'ca' || !trabajo?.firma_telematica_lang">Localidad</label>
                                                    <label for="localidad_id" class="col-form-label requerido" *ngIf="trabajo?.firma_telematica_lang == 'en'">Town</label>
                                                    <select class="form-control" formControlName="localidad_id" name="localidad_id"> 
                                                        <option value="" selected>Elige una opción</option>
                                                        <option *ngFor="let item of ciudades" value = "{{item.id}}">{{item.nombre}}</option>
                                                    </select>
                                                    </div>
                                                </div>  
                                                <div class="row mb-2">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="formrow-direccion-input" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Dirección (calle, vía, avda...)</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Adreça (carrer, via, avinguda...)</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Address (street, road, avenue...)</span>
                                                            </label>
                                                            <input type="text" class="form-control" formControlName="direccion" id="formrow-direccion-input" placeholder="Dirección (calle, vía, avda...)">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="formrow-cp-input" class="requerido">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Código Postal</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Codi Postal</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Postal Code</span>
                                                            </label>
                                                            <input (keypress)="inputNumerico($event)" type="text" class="form-control" id="formrow-cp-input" formControlName="cp" placeholder="00000">
                                                        </div>
                                                    </div>
                                                </div>
                        
                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!dni_frontal && dnirequerido">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="alert alert-danger fade show mt-0 mb-3" *ngIf="dnirequerido && !mostrar_dni" role="alert">
                                                        <i class="bx bx-info-circle font-size-17 align-middle mr-1"></i>
                                                        <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Para generar el consentimiento debes introducir la imagen frontal y trasera de tu DNI.</span>
                                                        <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Per generar el consentiment has d'introduir la imatge frontal i posterior del teu DNI.</span>
                                                        <span *ngIf="trabajo?.firma_telematica_lang == 'en'">To generate the consent, you must upload the front and back image of your ID card.</span>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <div class="custom-control custom-switch mb-0" dir="ltr">
                                                            <input type="checkbox" class="custom-control-input" id="mostrar_dni" (change)="changeMostrarDNI()">
                                                            <label class="custom-control-label" for="mostrar_dni">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Incluir la imagen de tu DNI en el consentimiento</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Incloure la imatge del teu DNI en el consentiment</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Include a picture of your ID card in the consent</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2" *ngIf="mostrar_dni">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="form-group col-md-6">
                                                            <label class="col-form-label">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Imagen frontal del DNI</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Imatge frontal del DNI</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Front ID Image</span>
                                                            </label>
                                                            <button class="btn btn-primary btn-block" type="button" (click)="openCamera('frontal', $event)">
                                                                <i class="bx bx-camera mr-1 align-middle"></i>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Capturar DNI Frontal</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Captura DNI Frontal</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Capture Front ID</span>
                                                            </button>
                                                            <!-- Opción para seleccionar la imagen del dispositivo -->
                                                            <input type="file" (change)="fileChangeEvent($event, fileInputFrontal)" #fileInputFrontal accept="image/*" hidden>
                                                            <button class="btn btn-secondary btn-block mt-2" type="button" (click)="fileInputFrontal.click()">
                                                                <i class="bx bxs-cloud-upload mr-1 align-middle"></i>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es'">Seleccionar imagen del dispositivo</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Seleccionar imatge del dispositiu</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Select image from device</span>
                                                            </button>
                                                            <!-- Fin de opción para seleccionar la imagen del dispositivo -->
                                                            <div class="mt-2" *ngIf="showFrontalCamera" style="position:relative;">
                                                                <div class="video-container">
                                                                    <video #videoElement [hidden]="!videoVisible" playsinline style="width:100%; object-fit: cover;"></video>
                                                                </div>
                                                                <div class="d-block w-auto" style="position:absolute;bottom: 3px;left: 3px;background-color: #fff;">
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang" class="text-dark small p-1 ">*Haz clic en la imagen para tomar la foto</span>
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'ca'" class="text-dark small p-1 ">*Prem sobre la imatge per fer la foto</span>
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'en'" class="text-dark small p-1 ">*Click on the image to take the photo</span>
                                                                </div>
                                                            </div>
                                                            <div class="w-100">
                                                                <canvas class="w-100 border border-dark rounded mt-2" #canvasFrontal></canvas>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label class="col-form-label">
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Imagen trasera del DNI</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Imatge del darrere del DNI</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Rear ID Image</span>
                                                            </label>
                                                            <button class="btn btn-primary btn-block" type="button" (click)="openCamera('trasera', $event)">
                                                                <i class="bx bx-camera mr-1 align-middle"></i>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Capturar DNI Trasera</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Captura DNI Darrere</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Capture Back ID</span>
                                                            </button>
                                                            <!-- Opción para seleccionar la imagen del dispositivo -->
                                                            <input type="file" (change)="fileChangeEvent2($event, fileInputTrasera)" #fileInputTrasera accept="image/*" hidden>
                                                            <button class="btn btn-secondary btn-block mt-2" type="button" (click)="fileInputTrasera.click()">
                                                                <i class="bx bxs-cloud-upload mr-1 align-middle"></i>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es'">Seleccionar imagen del dispositivo</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Seleccionar imatge del dispositiu</span>
                                                                <span *ngIf="trabajo?.firma_telematica_lang == 'en'">Select image from device</span>
                                                            </button>
                                                            <!-- Fin de opción para seleccionar la imagen del dispositivo -->
                                                            <div class="mt-2" *ngIf="showTraseraCamera" style="position:relative;">
                                                                <div class="video-container">
                                                                    <video #videoElement [hidden]="!videoVisible" playsinline style="width:100%; object-fit: cover;"></video>
                                                                </div>
                                                                <div class="d-block w-auto" style="position:absolute;bottom: 3px;left: 3px;background-color: #fff;">
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang" class="text-dark small p-1 ">*Haz clic en la imagen para tomar la foto</span>
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'ca'" class="text-dark small p-1 ">*Prem sobre la imatge per fer la foto</span>
                                                                    <span *ngIf="trabajo?.firma_telematica_lang == 'en'" class="text-dark small p-1 ">*Click on the image to take the photo</span>
                                                                </div>
                                                            </div>
                                                            <div class="w-100">
                                                                <canvas class="w-100 border border-dark rounded mt-2" #canvasTrasera></canvas>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card bg-transparent">
                                        <div class="card-body p-0">
                                            <button type="submit" [disabled]="formCliente.invalid" class="btn btn-primary w-md">
                                                <span *ngIf="trabajo?.firma_telematica_lang == 'es' || trabajo?.firma_telematica_lang == 'null' || !trabajo?.firma_telematica_lang">Guardar</span><span *ngIf="trabajo?.firma_telematica_lang == 'ca'">Guardar</span><span *ngIf="trabajo?.firma_telematica_lang == 'en'">Save</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                
                    <div class="paso-firma" *ngIf="pasoFirma">
                        <div class="card">
                            <div class="card-body">
                            
                                <!-- Barra negra título superior para consentimiento castellano & catalán -->
                                <div class="container-fluid" *ngIf="trabajo?.idioma_consentimiento != 'and'">
                                    <div class="row d-flex justify-content-between border border-dark bg-dark mb-3 p-3">
                                        <div class="col p-0">
                                            <div class="d-flex justify-content-start">
                                                <h4 class="font-size-12 font-weight-bold mb-0 text-white" *ngIf="trabajo?.idioma_consentimiento == 'es'">Consentimiento informado #{{ trabajo?.id }}</h4>
                                                <h4 class="font-size-12 font-weight-bold mb-0 text-white" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Consentiment informat #{{ trabajo?.id }}</h4>
                                            </div>
                                        </div>
                                        <div class="col p-0">
                                            <div class="d-flex justify-content-end">
                                                <h4 class="font-size-12 mb-0 text-white" *ngIf="trabajo?.estudio.localidad_id">{{ trabajo?.estudio.localidad.nombre }}, {{ today }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Consentimiento castellano & catalán foto dni -->
                                <div class="container-fluid" *ngIf="dni_frontal && dni_trasera && (trabajo?.idioma_consentimiento == 'es' || trabajo?.idioma_consentimiento == 'ca')">
                                    <div class="row d-flex justify-content-between">
                                        <div class="col border border-dark mb-3 p-1 mr-3">
                                            <div class="d-flex justify-content-center">
                                                <img src="{{ global_url + dni_frontal?.url }}" class="img-fluid" width="100%">
                                            </div>
                                        </div>
                                        <div class="col border border-dark mb-3 p-1">
                                            <div class="d-flex justify-content-center">
                                                <img src="{{ global_url + dni_trasera?.url }}" class="img-fluid" width="100%">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Consentimiento castellano, catalán, inglés -->
                                <div *ngIf="trabajo?.idioma_consentimiento == 'es' || trabajo?.idioma_consentimiento == 'ca' || trabajo?.idioma_consentimiento == 'en'">

                                    <div class="col-md-12 border border-dark mb-3 p-3">
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">MODEL DE CONSENTIMENT INFORMAT PER TATUATGE</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">MODELO DE CONSENTIMIENTO INFORMADO PARA TATUAJE</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">INFORMED CONSENT MODEL FOR TATTOOING</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 3)">MODEL DE CONSENTIMENT INFORMAT PER PÍRCING</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 3)">MODELO DE CONSENTIMIENTO INFORMADO PARA PIERCING</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 3)">INFORMED CONSENT MODEL FOR PIERCING</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 4)">CONSENTIMENT INFORMAT PER A LA ELIMINACIÓ DE TATUATGES, MICROPIGMENTACIÓ O EYELINER AMB LÀSER</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 4)">CONSENTIMIENTO INFORMADO PARA LA ELIMINACIÓN DE TATUAJES, MICROPIGMENTACIÓN O EYELINER CON LÁSER</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 4)">INFORMED CONSENT FOR THE REMOVAL OF TATTOOS, MICROPIGMENTATION, OR EYELINER WITH LASER</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 5)">MODEL DE CONSENTIMENT INFORMAT PER MICROPIGMENTACIÓ <span *ngIf="trabajo?.fase_cita == 5">- LLAVIS</span><span *ngIf="trabajo?.fase_cita == 6">- CELLES</span><span *ngIf="trabajo?.fase_cita == 7">- EYELINER</span></h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 5)">MODELO DE CONSENTIMIENTO INFORMADO PARA MICROPIGMENTACIÓN <span *ngIf="trabajo?.fase_cita == 5">- LABIOS</span><span *ngIf="trabajo?.fase_cita == 6">- CEJAS</span><span *ngIf="trabajo?.fase_cita == 7">- EYELINER</span><span *ngIf="trabajo?.fase_cita == 8">- MICROBLADING</span></h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 5)">INFORMED CONSENT MODEL FOR MICROPIGMENTATION <span *ngIf="trabajo?.fase_cita == 5">- LIPS</span><span *ngIf="trabajo?.fase_cita == 6">- EYEBROWS</span><span *ngIf="trabajo?.fase_cita == 7">- EYELINER</span><span *ngIf="trabajo?.fase_cita == 8">- MICROBLADING</span></h5>

                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 31)">CONSENTIMENT INFORMAT PER A GEMMES DENTALS</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 31)">CONSENTIMIENTO INFORMADO PARA GEMAS DENTALES</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 31)">INFORMED CONSENT FOR DENTAL GEMS</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 28)">CONSENTIMENT INFORMAT PER A DEPILACIÓ FACIAL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 28)">CONSENTIMIENTO INFORMADO PARA DEPILACIÓN FACIAL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 28)">INFORMED CONSENT FOR FACIAL HAIR REMOVAL</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 29)">CONSENTIMENT INFORMAT PER A LIFTING DE PESTANYES</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 29)">CONSENTIMIENTO INFORMADO PARA LIFTING DE PESTAÑAS</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 29)">INFORMED CONSENT FOR EYELASH LIFTING</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 20)">CONSENTIMENT INFORMAT PER A EXTENSIÒ DE PESTANYES</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 20)">CONSENTIMIENTO INFORMADO PARA EXTENSIÓN DE PESTAÑAS</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 20)">INFORMED CONSENT FOR EYELASH EXTENSIONS</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 22)">CONSENTIMENT INFORMAT PER A CARBON PEEL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 22)">CONSENTIMIENTO INFORMADO PARA CARBON PEEL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 22)">INFORMED CONSENT FOR CARBON PEEL</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 23)">CONSENTIMENT INFORMAT PER A REJOVENIMENT FACIAL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 23)">CONSENTIMIENTO INFORMADO PARA REJUVENECIMIENTO FACIAL</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 23)">INFORMED CONSENT FOR FACIAL REJUVENATION</h5>
                                        
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30)">CONSENTIMENT INFORMAT PER A LAMINAT DE CELLES</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30)">CONSENTIMIENTO INFORMADO PARA LAMINADO DE CEJAS</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30)">INFORMED CONSENT FOR EYEBROW LAMINATION</h5>

                                        <hr class="mt-0 mb-2">
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Informació inicial</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">Información inicial</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">Initial Information</p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Denominació comercial de l’establiment:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Denominación comercial del establecimiento:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Commercial name of the establishment:</strong>
                                            {{ trabajo?.estudio.nombre }}
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.estudio.regsanitario">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Número d'Autorització Sanitària:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Nº de Registro Sanitario:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Health Registration Number:</strong>
                                            {{ trabajo?.estudio.regsanitario }}
                                        </p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Adreça:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Dirección:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Address:</strong>
                                            {{ trabajo?.estudio.direccion }} {{ trabajo?.estudio.localidad.nombre }}.
                                        </p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Telèfon:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Teléfono:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Phone:</strong>
                                            {{ trabajo?.estudio.telefono }}
                                        </p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Adreça electrònica:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Email:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Email:</strong>
                                            {{ trabajo?.estudio.email }}
                                        </p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Nom i cognoms de la persona aplicadora:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Nombre y apellidos del aplicador:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Name and surname of the practitioner:</strong>
                                            {{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }}
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.estudio.comunidad_id == 1 && trabajo?.artista.titulacion_academica">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Titulación académica del aplicador:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Titulación académica del aplicador:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Titulation of the practitioner:</strong>
                                            {{ trabajo?.artista.titulacion_academica }} 
                                        </p>
                                        <p class="mb-0">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Zona del cos a <span *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">tatuar</span> <span *ngIf="trabajo?.tipo_proyecto == 3">perforar</span><span *ngIf="trabajo?.tipo_proyecto == 4">tractar amb làser</span><span *ngIf="trabajo?.tipo_proyecto == 5">micropigmentar</span>:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Zona del cuerpo a <span *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">tatuar</span> <span *ngIf="trabajo?.tipo_proyecto == 3">perforar</span> <span *ngIf="trabajo?.tipo_proyecto == 4">tratar con láser</span><span *ngIf="trabajo?.tipo_proyecto == 5">micropigmentar</span>:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Body area to <span *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">be tattooed</span> <span *ngIf="trabajo?.tipo_proyecto == 3">be pierced</span><span *ngIf="trabajo?.tipo_proyecto == 4">be treated with laser</span><span *ngIf="trabajo?.tipo_proyecto == 5">be micropigmented</span>:</strong>
                                            <span *ngFor="let zona of trabajo?.zonas"> {{ zona.nombre }}, </span>
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2 || trabajo?.tipo_proyecto == 5">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Núm. sessió actual:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Núm. de sesión actual:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Current session number:</strong>
                                            {{ trabajo?.sesion }} - (<span *ngIf="trabajo?.idioma_consentimiento == 'ca'">cada sessió tindrà el seu consentiment informat</span><span *ngIf="trabajo?.idioma_consentimiento == 'es'">cada sesión tendrá su consentimiento informado</span><span *ngIf="trabajo?.idioma_consentimiento == 'en'">each session will have its informed consent</span>.)
                                        </p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2 || trabajo?.tipo_proyecto == 5)">Respecte a les tintes emprades:</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2 || trabajo?.tipo_proyecto == 5)">Respecto a las tintas usadas:</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2 || trabajo?.tipo_proyecto == 5)">Regarding the inks used:</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'"><strong>Pigments autoritzats per l'Agència Espanyola de Medicaments i Productes Sanitaris.</strong></p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'"><strong>Pigmentos autorizados por la Agencia Española de Medicamentos y Productos Sanitarios.</strong></p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'"><strong>Pigments authorized by the Spanish Agency of Medicines and Medical Devices.</strong></p>
                                        
                                        <div class="tintas_bloque d-none" *ngIf="trabajo?.tipo_proyecto == 1 || trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2 || trabajo?.tipo_proyecto == 5">
                                            <form [formGroup]="formInks">
                                                <table style="width:100%" class="mb-2" formArrayName="inks_used" *ngIf="inksUsed.controls.length > 0">
                                                    <thead>
                                                        <th>Nom</th>
                                                        <th>Color</th>
                                                        <th>Nº de Lote</th>
                                                        <th>Registro</th>
                                                        <th>Código</th>
                                                        <th>Caducidad</th>
                                                    </thead>
                                                    <tr *ngFor="let item of inksUsed.controls; let pointIndex=index" [formGroupName]="pointIndex">
                                                        <td><input type="text" class="form-control" formControlName="nombre" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                        <td><input type="text" class="form-control" formControlName="color" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                        <td><input type="text" class="form-control" formControlName="num_lote" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                        <td><input type="text" class="form-control" formControlName="registro" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                        <td><input type="text" class="form-control" formControlName="codigo" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                        <td><input type="text" class="form-control" formControlName="caducidad" [ngClass]="{'disabled_tinta': item.value.tinta_id}"></td>
                                                    </tr>
                                                </table>
                                            </form>
                                        </div>

                                    </div>
                                
                                    <div class="col-md-12 border border-dark mb-3 p-3">
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Informació detallada sobre Protecció de Dades</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es'">Información detallada sobre Protección de Datos</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en'">Detailed information about Data Protection</h5>
                                        <hr class="mt-0 mb-2">
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Qui és el responsable del tractament de les vostres dades?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Quién es el responsable del tratamiento de sus datos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">Who is responsible for the processing of your data?</p>
                                        <!-- El responsable SI es el estudio -->
                                        <p class="mb-0" *ngIf="trabajo?.estudio.responsable_datos != 1">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Identitat:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Identidad:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Identity:</strong>
                                            {{ trabajo?.estudio.razonsocial }}
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.estudio.responsable_datos != 1">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Adreça postal:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Dirección:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Address:</strong>
                                            {{ trabajo?.estudio.direccion }} {{ trabajo?.estudio.localidad.nombre }}.
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.estudio.responsable_datos != 1">
                                            <strong>NIF:</strong>
                                            {{ trabajo?.estudio.cif }}
                                        </p>
                                        <p class="mb-0" *ngIf="trabajo?.estudio.responsable_datos != 1">
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'ca'">Telèfon:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'es'">Teléfono:</strong>
                                            <strong *ngIf="trabajo?.idioma_consentimiento == 'en'">Phone number:</strong>
                                            {{ trabajo?.estudio.telefono }}
                                        </p>
                                        <!-- El responsable NO es el estudio -->
                                        <p class="mb-0" *ngIf="trabajo?.estudio.responsable_datos == 1">
                                            {{ trabajo?.estudio.info_responsable_datos }}
                                        </p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Amb quina finalitat tractem les vostres dades personals?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Con qué finalidad tratamos sus datos personales?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">What is the purpose of processing your personal data?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Les dades personals que ens ha facilitat son per dur a terme la correcta prestació dels serveis contractats, mantenir la relació comercial i poder-vos trametre les comunicacions que es considerin d'interès.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Los datos personales que nos ha facilitado son para llevar a cabo la correcta prestación de los servicios contratados, mantener la relación comercial y poder remitirle las comunicaciones que se consideren de interés.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">The personal data that you have provided us are used to carry out the proper provision of contracted services, maintain the commercial relationship, and send you communications that are deemed of interest.</p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Quant de temps conservarem les vostres dades?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Cuánto tiempo conservaremos sus datos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">How long will we keep your data?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Les dades personals proporcionades es conservaran mentre l’interessat no en sol·liciti la supressió o siguin necessaris per al compliment de una obligació legal.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Los datos personales proporcionados se conservarán mientras el interesado no solicite su supresión o sean necesarios para el cumplimiento de una obligación legal.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">The personal data provided will be kept as long as the interested party does not request its deletion or they are necessary to fulfill a legal obligation.</p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Quina és la legitimació per al tractament de les vostres dades?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Cuál es la legitimación para el tratamiento de sus datos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">What is the legitimacy for the processing of your data?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Consentiment de l'afectat</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">El consentimiento del cliente</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">The client's consent.</p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">A quins destinataris es comunicaran les vostres dades?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿A qué destinatarios se comunicarán sus datos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">To which recipients will your data be communicated?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Les vostres dades no es comunicaran a altres categories de destinataris, excepte en els casos previstos per la llei.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Sus datos no se comunicarán a otras categorías de destinatarios, salvo en los casos previstos por la ley.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">Your data will not be communicated to other categories of recipients, except in cases provided for by law.</p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Quins són els vostres drets?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Cuáles son sus derechos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">What are your rights?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Qualsevol persona té dret a obtenir confirmació sobre si denominació comercial establiment estan tractant dades personals que la concerneixen, o no. Les persones interessades tenen dret a accedir a les seves dades personals, així com sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar-ne la supressió, entre altres motius, quan les dades ja no siguin necessàries per a les finalitats per a les quals es van recollir.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Cualquier persona tiene derecho a obtener confirmación sobre si denominación comercial establecimiento están tratando datos personales que la atañen, o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión, entre otros motivos, cuando los datos ya no sean necesarios para las finalidades para las que se recogieron.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">Any person has the right to obtain confirmation as to whether or not the commercial name establishment is processing personal data concerning them. Interested parties have the right to access their personal data, as well as to request the rectification of inaccurate data or, where appropriate, their deletion, among other reasons, when the data are no longer necessary for the purposes for which they were collected.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">En determinades circumstàncies previstes a l’article 18 RGPD, els interessats poden sol·licitar la limitació del tractament de les seves dades. En aquest cas, si escau, únicament les conservarem per exercir o defensar reclamacions.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">En determinadas circunstancias previstas en el artículo 18 RGPD, los interesados pueden solicitar la limitación del tratamiento de sus datos. En este caso, en su caso, únicamente las conservaremos para ejercitar o defender reclamaciones.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">Under certain circumstances set out in Article 18 of the GDPR, interested parties may request the limitation of the processing of their data. In this case, we will only keep them for the exercise or defense of claims.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">En determinades circumstàncies i per motius relacionats amb la seva situació particular, les persones interessades podran oposar-se al tractament de les seves dades. En aquests casos, si escau, denominació comercial establiment deixarà de tractar les dades, tret de motius legítims imperiosos o per exercir o defensar possibles reclamacions.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">En determinadas circunstancias y por motivos relacionados con su situación particular, las personas interesadas podrán oponerse al tratamiento de sus datos. En estos casos, en su caso, denominación comercial establecimiento dejará de tratar los datos, salvo motivos legítimos imperiosos o para ejercitar o defender posibles reclamaciones.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">Under certain circumstances and for reasons related to their particular situation, interested parties may object to the processing of their data. In this case, we will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">En virtut del dret a la portabilitat, els interessats tenen dret a obtenir les seves dades personals en un format estructurar d’ús comú i lectura mecànica i a transmetre-les a un altre responsable.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">En virtud del derecho a la portabilidad, los interesados tienen derecho a obtener sus datos personales en un formato estructurar de uso común y lectura mecánica y transmitirlos a otro responsable.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">In accordance with the right to data portability, interested parties have the right to receive their personal data in a structured, commonly used and machine-readable format and to transmit those data to another controller.</p>
                
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Com podeu exercir els drets?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Cómo puede ejercer los derechos?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">How can you exercise your rights?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Mitjançat un escrit, adjuntant fotocòpia del DNI o document identificatiu equivalent, dirigit a l’adreça de l’ Establiment.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Mediante escrito, adjuntando fotocopia del DNI o documento identificativo equivalente, dirigido a la dirección del Establecimiento.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">You can exercise your rights by sending a written request, along with a copy of your ID card or equivalent identifying document, to the establishment's address.</p>
                                        
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Quines vies de reclamació hi ha?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es'">¿Qué vías de reclamación existen?</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'en'">What recourse options are available?</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">Si considereu que els vostres drets no s’han atès adequadament, teniu dret a presentar una reclamació davant l' Agència Espanyola de protecció de dades.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">Si considera que sus derechos no se han atendido adecuadamente, tiene derecho a presentar una reclamación ante la Agencia Española de protección de datos.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">If you believe that your rights have not been properly addressed, you have the right to file a complaint with the Spanish Data Protection Agency.</p>
                                    </div>
                
                                    <div class="col-md-12 border border-dark mb-3 p-3" *ngIf="trabajo?.tipo_proyecto != 4">
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca'">INFORMACIÓ ANNEXA PER AL CLIENT</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es'">INFORMACIÓN ANEXA PARA EL CLIENTE</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en'">ADDITIONAL INFORMATION FOR THE CLIENT</h5>
                                        <hr class="mt-0 mb-2">
                                        
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">El tatuatge consisteix en decorar el cos humà amb pigments colorants que s’introdueixen a la pell fent unes puncions amb agulles. És un fet permanent i qualsevol sistema utilitzat per a la seva retirada pot comportar riscos per la salut. Els coneixement dels riscos i l’acceptació prèvia a la pràctica a realitzar pot evitar patiments innecessaris a l’usuari. {{ trabajo?.estudio?.descripcion_tecnica }}</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">El tatuaje consiste en decorar el cuerpo humano con pigmentos colorantes que se introducen en la piel haciendo unas punciones con alfileres. Es un hecho permanente y cualquier sistema utilizado para su retirada puede acarrear riesgos para la salud. El conocimiento de los riesgos y la aceptación previa a la práctica a realizar puede evitar sufrimientos innecesarios al usuario.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">Tattooing involves decorating the human body with colored pigments that are inserted into the skin by making punctures with needles. It is a permanent process, and any system used for its removal may pose risks to health. Knowledge of the risks and prior acceptance of the practice to be carried out can prevent unnecessary suffering for the user.</p>
                                        
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 3)">El pírcing consisteix a perforar la pell, les mucoses o altres teixits amb la finalitat de col·locar-hi joies o altres objectes de metall. El coneixement dels riscos i l’acceptació prèvia a la pràctica a realitzar pot evitar patiments innecessaris a l’usuari.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 3)">El piercing consiste en perforar la piel, las mucosas u otros tejidos con el fin de colocar joyas u otros objetos de metal. El conocimiento de los riesgos y la aceptación previa a la práctica a realizar puede evitar sufrimientos innecesarios al usuario.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 3)">Piercing involves piercing the skin, mucous membranes, or other tissues in order to place metal jewelry or other objects. Knowledge of the risks and prior acceptance of the practice to be carried out can prevent unnecessary suffering for the user.</p>
                                        
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.tipo_proyecto == 5)">La micropigmentació es un procediment estètic que té com a finalitat l’embelliment de diferents parts del cos i/o el camuflatge de cicatrius, cremades i altres alteracions de la capa més visible de la pell. També implica puncions o talls. Qualsevol sistema utilitzat per a la seva retirada pot comportar riscos per a la salut.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.tipo_proyecto == 5)">La micropigmentación es un procedimiento estético que tiene como finalidad el embellecimiento de distintas partes del cuerpo y/o el camuflaje de cicatrices, quemaduras y otras alteraciones de la capa más visible de la piel. También implica punciones o cortes. Cualquier sistema utilizado para su retirada puede acarrear riesgos para la salud.</p>
                                        <p class="mb-0" *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.tipo_proyecto == 5)">Micropigmentation is a cosmetic procedure that aims to beautify different parts of the body and/or camouflage scars, burns, and other alterations of the most visible layer of the skin. It also involves punctures or cuts. Any system used for its removal may pose risks to health.</p>
                                        
                
                                        <!--------------------------------->
                                        <!------CONTRAINDICACIONES--------->
                                        <!--------------------------------->
                                        <!-- Si español y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicaciones tatuaje</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_tattoo_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_tattoo_check == 0">
                                                <p><strong>No se podrá realizar bajo ninguna circunstancia</strong></p>
                                                <p class="m-0">• En caso de ser alérgico a los pigmentos.</p>
                                                <p class="m-0">• Cuando la zona donde se desea hacer tiene afecciones de la piel como pecas, manchas, verrugas, o lesiones cutáneas no diagnosticadas.</p>
                                                <p class="m-0">• Si padece alguna enfermedad de la piel como psoriasis, urticaria, etc.</p>
                                                <p><strong>Será recomendable un informe médico</strong></p>
                                                <p class="m-0">• En caso de sufrir algunas infecciones o enfermedades como diabetes, afectaciones cardíacas o renales, hepatitis, VIH, etc., ya que disminuyen las defensas y complicaciones pueden ser más graves.</p>
                                                <p class="m-0">• Si se padecen enfermedades de la sangre o si se está realizando un tratamiento anticoagulante con acenocumarol (Sintrom ®), ya que se pueden tener hemorragias.</p>
                                                <p class="m-0">• Si recientemente se ha sufrido un tratamiento para el acné con isotretinoína (Isoacné®, Dercutane®, Acnemin®, etc.), dado que son tratamientos que modifican la piel y las mucosas, pudiéndose sangrar con mayor facilidad.</p>
                                                <p class="m-0">• Si se tiene epilepsia ya que podría desencadenarse un ataque.</p>
                                                <p class="m-0">• Si se sufren trastornos psicológicos o de la personalidad.</p>
                                                <p><strong>De forma temporal</strong></p>
                                                <p class="m-0">• En caso de sufrir alguna afectación aguda como pueden ser las infecciones locales por verrugas víricas, herpes simple o infecciones bacterianas. Es necesario esperar a que el proceso patológico se resuelva.</p>
                                                <p class="m-0">• Si ha tenido muy recientemente infiltraciones médico estéticas, intervenciones quirúrgicas, quimioterapia o radioterapia, o tiene cicatrices no estabilizadas, quemaduras recientes, úlceras o hematomas. En estos casos habrá que esperar a que la debilidad inmunológica se estabilice.</p>
                                                <p class="m-0">• Situaciones fisiológicas como embarazo o lactancia. Son circunstancias en las que es difícil el tratamiento de las posibles complicaciones por el peligro de transmisión de los agentes que pueden causar efectos no deseables al bebé.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Tattoo contraindications</p>
                                            <div>
                                                <p><strong>It cannot be performed under any circumstance</strong></p>
                                                <p class="m-0">• In case of being allergic to pigments.</p>
                                                <p class="m-0">• When the desired area has skin conditions such as freckles, spots, warts, or undiagnosed skin lesions.</p>
                                                <p class="m-0">• If you suffer from any skin disease such as psoriasis, hives, etc.</p>
                                                <p><strong>A medical report will be recommended</strong></p>
                                                <p class="m-0">• In case of suffering from some infections or diseases such as diabetes, heart or kidney conditions, hepatitis, HIV, etc., since they lower defenses and complications can be more severe.</p>
                                                <p class="m-0">• If you have blood diseases or are undergoing anticoagulant treatment with acenocoumarol (Sintrom®), as bleeding may occur.</p>
                                                <p class="m-0">• If you have recently undergone acne treatment with isotretinoin (Isoacné®, Dercutane®, Acnemin®, etc.), since they are treatments that modify the skin and mucous membranes, making them more prone to bleeding.</p>
                                                <p class="m-0">• If you have epilepsy, as it could trigger a seizure.</p>
                                                <p class="m-0">• If you suffer from psychological or personality disorders.</p>
                                                <p><strong>Temporarily</strong></p>
                                                <p class="m-0">• In case of suffering from an acute condition such as local infections due to viral warts, herpes simplex or bacterial infections. It is necessary to wait for the pathological process to resolve.</p>
                                                <p class="m-0">• If you have recently had medical-aesthetic infiltrations, surgical interventions, chemotherapy or radiotherapy, or have unstable scars, recent burns, ulcers or hematomas. In these cases, it will be necessary to wait until the immune weakness stabilizes.</p>
                                                <p class="m-0">• Physiological situations such as pregnancy or lactation. These are circumstances in which the treatment of possible complications is difficult due to the risk of transmission of agents that can cause undesirable effects on the baby.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicacions tatuatge</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_tattoo_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_tattoo_check == 0">
                                                <p><strong>No es podrà realitzar sota cap circumstància</strong></p>
                                                    <p class="m-0">• En cas de ser al·lèrgic als pigments.</p>
                                                    <p class="m-0">• Quan la zona on es vol fer té afeccions de la pell com pigues, taques, berrugues, o lesions cutànies no diagnosticades.</p>
                                                    <p class="m-0">• Si pateix alguna malaltia de la pell com psoriasi, urticària, etc.</p>
                                                    <p><strong>Serà recomanable un informe mèdic</strong></p>
                                                    <p class="m-0">• En cas de patir algunes infeccions o malalties com diabetis, afectacions cardíaques o renals, hepatitis, VIH, etc., ja que disminueixen les defenses i les complicacions poden ser més greus.</p>
                                                    <p class="m-0">• Si es pateixen malalties de la sang o si s’està fent un tractament anticoagulant amb acenocumarol (Sintrom ®), ja que es poden tenir hemorràgies.</p>
                                                    <p class="m-0">• Si recentment s’ha sofert un tractament per a l’acne amb isotretinoína (Isoacné ®, Dercutane ®, Acnemin ®, etc.), donat que són tractaments que modifiquen la pell i les mucoses, i es podria sagnar amb més facilitat.</p>
                                                    <p class="m-0">• Si es té epilèpsia ja que podria desencadenar-se un atac.</p>
                                                    <p class="m-0">• Si es pateixen trastorns psicològics o de la personalitat.</p>
                                                    <p><strong>De forma temporal</strong></p>
                                                    <p class="m-0">• En cas de patir alguna afectació aguda com poden ser les infeccions locals per berrugues víriques, herpes simple o infeccions bacterianes. És necessari esperar que el procés patològic es resolgui.</p>
                                                    <p class="m-0">• Si ha tingut molt recentment infiltracions medico estètiques, intervencions quirúrgiques, quimioteràpia o radioteràpia, o té cicatrius no estabilitzades, cremades recents, úlceres o hematomes. En aquests casos caldrà esperar que la debilitat immunològica s’estabilitzi.</p>
                                                    <p class="m-0">• Situacions fisiològiques com l’embaràs o la lactància. Són circumstàncies en les que és difícil el tractament de les possibles complicacions pel perill de transmissió dels agents que poden causar efectes no desitjables al nadó.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicaciones piercing</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_piercing_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_piercing_check == 0">
                                                <p><strong>No se podrá realizar bajo ninguna circunstancia</strong></p>
                                                <p class="m-0">• En caso de ser alérgico a los pigmentos.</p>
                                                <p class="m-0">• Cuando la zona donde se desea hacer tiene afecciones de la piel como pecas, manchas, verrugas, o lesiones cutáneas no diagnosticadas.</p>
                                                <p class="m-0">• Si padece alguna enfermedad de la piel como psoriasis, urticaria, etc.</p>
                                                <p><strong>Será recomendable un informe médico</strong></p>
                                                <p class="m-0">• En caso de sufrir algunas infecciones o enfermedades como diabetes, afectaciones cardíacas o renales, hepatitis, VIH, etc., ya que disminuyen las defensas y complicaciones pueden ser más graves.</p>
                                                <p class="m-0">• Si se padecen enfermedades de la sangre o si se está realizando un tratamiento anticoagulante con acenocumarol (Sintrom ®), ya que se pueden tener hemorragias.</p>
                                                <p class="m-0">• Si recientemente se ha sufrido un tratamiento para el acné con isotretinoína (Isoacné®, Dercutane®, Acnemin®, etc.), dado que son tratamientos que modifican la piel y las mucosas, pudiéndose sangrar con mayor facilidad.</p>
                                                <p class="m-0">• Si se tiene epilepsia ya que podría desencadenarse un ataque.</p>
                                                <p class="m-0">• Si se sufren trastornos psicológicos o de la personalidad.</p>
                                                <p><strong>De forma temporal</strong></p>
                                                <p class="m-0">• En caso de sufrir alguna afectación aguda como pueden ser las infecciones locales por verrugas víricas, herpes simple o infecciones bacterianas. Es necesario esperar a que el proceso patológico se resuelva.</p>
                                                <p class="m-0">• Si ha tenido muy recientemente infiltraciones médico estéticas, intervenciones quirúrgicas, quimioterapia o radioterapia, o tiene cicatrices no estabilizadas, quemaduras recientes, úlceras o hematomas. En estos casos habrá que esperar a que la debilidad inmunológica se estabilice.</p>
                                                <p class="m-0">• Situaciones fisiológicas como embarazo o lactancia. Son circunstancias en las que es difícil el tratamiento de las posibles complicaciones por el peligro de transmisión de los agentes que pueden causar efectos no deseables al bebé.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Piercing contraindications</p>
                                            <div>
                                                <p><strong>It cannot be performed under any circumstances</strong></p>
                                                <p class="m-0">• In case of being allergic to pigments.</p>
                                                <p class="m-0">• When the area where it is desired to be done has skin conditions such as freckles, spots, warts, or undiagnosed skin lesions.</p>
                                                <p class="m-0">• If you have any skin disease such as psoriasis, urticaria, etc.</p>
                                                <p><strong>A medical report will be recommended</strong></p>
                                                <p class="m-0">• In case of suffering from infections or diseases such as diabetes, heart or kidney problems, hepatitis, HIV, etc., as defenses are diminished and complications can be more serious.</p>
                                                <p class="m-0">• If you have blood diseases or are undergoing anticoagulant treatment with acenocoumarol (Sintrom ®), as bleeding can occur.</p>
                                                <p class="m-0">• If you have recently undergone treatment for acne with isotretinoin (Isoacné®, Dercutane®, Acnemin®, etc.), as they are treatments that modify the skin and mucous membranes, which can bleed more easily.</p>
                                                <p class="m-0">• If you have epilepsy as it could trigger a seizure.</p>
                                                <p class="m-0">• If you suffer from psychological or personality disorders.</p>
                                                <p><strong>Temporarily</strong></p>
                                                <p class="m-0">• In case of suffering from acute conditions such as local infections by viral warts, herpes simplex or bacterial infections. It is necessary to wait for the pathological process to resolve.</p>
                                                <p class="m-0">• If you have recently undergone medical aesthetic infiltrations, surgical interventions, chemotherapy or radiotherapy, or have unstable scars, recent burns, ulcers, or hematomas. In these cases, it will be necessary to wait for the immune weakness to stabilize.</p>
                                                <p class="m-0">• Physiological situations such as pregnancy or lactation. These are circumstances in which it is difficult to treat possible complications due to the risk of transmission of agents that can cause undesirable effects on the baby.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicacions pírcing</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_piercing_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_piercing_check == 0">
                                                <p><strong>No es podrà realitzar sota cap circumstància</strong></p>
                                                <p class="m-0">• En cas de ser al·lèrgic als pigments.</p>
                                                <p class="m-0">• Quan la zona on es vol fer té afeccions de la pell com pigues, taques, berrugues, o lesions cutànies no diagnosticades.</p>
                                                <p class="m-0">• Si pateix alguna malaltia de la pell com psoriasi, urticària, etc.</p>
                                                <p><strong>Serà recomanable un informe mèdic</strong></p>
                                                <p class="m-0">• En cas de patir algunes infeccions o malalties com diabetis, afectacions cardíaques o renals, hepatitis, VIH, etc., ja que disminueixen les defenses i les complicacions poden ser més greus.</p>
                                                <p class="m-0">• Si es pateixen malalties de la sang o si s’està fent un tractament anticoagulant amb acenocumarol (Sintrom ®), ja que es poden tenir hemorràgies.</p>
                                                <p class="m-0">• Si recentment s’ha sofert un tractament per a l’acne amb isotretinoína (Isoacné ®, Dercutane ®, Acnemin ®, etc.), donat que són tractaments que modifiquen la pell i les mucoses, i es podria sagnar amb més facilitat.</p>
                                                <p class="m-0">• Si es té epilèpsia ja que podria desencadenar-se un atac.</p>
                                                <p class="m-0">• Si es pateixen trastorns psicològics o de la personalitat.</p>
                                                <p><strong>De forma temporal</strong></p>
                                                <p class="m-0">• En cas de patir alguna afectació aguda com poden ser les infeccions locals per berrugues víriques, herpes simple o infeccions bacterianes. És necessari esperar que el procés patològic es resolgui.</p>
                                                <p class="m-0">• Si ha tingut molt recentment infiltracions medico estètiques, intervencions quirúrgiques, quimioteràpia o radioteràpia, o té cicatrius no estabilitzades, cremades recents, úlceres o hematomes. En aquests casos caldrà esperar que la debilitat immunològica s’estabilitzi.</p>
                                                <p class="m-0">• Situacions fisiològiques com l’embaràs o la lactància. Són circumstàncies en les que és difícil el tractament de les possibles complicacions pel perill de transmissió dels agents que poden causar efectes no desitjables al nadó.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicaciones micropigmentación</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_microblading_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_microblading_check == 0">
                                                <p><strong>No se podrá realizar bajo ninguna circunstancia</strong></p>
                                                <p class="m-0">• En caso de ser alérgico a los pigmentos.</p>
                                                <p class="m-0">• Cuando la zona donde se desea hacer tiene afecciones de la piel como pecas, manchas, verrugas, o lesiones cutáneas no diagnosticadas.</p>
                                                <p class="m-0">• Si padece alguna enfermedad de la piel como psoriasis, urticaria, etc.</p>
                                                <p><strong>Será recomendable un informe médico</strong></p>
                                                <p class="m-0">• En caso de sufrir algunas infecciones o enfermedades como diabetes, afectaciones cardíacas o renales, hepatitis, VIH, etc., ya que disminuyen las defensas y complicaciones pueden ser más graves.</p>
                                                <p class="m-0">• Si se padecen enfermedades de la sangre o si se está realizando un tratamiento anticoagulante con acenocumarol (Sintrom ®), ya que se pueden tener hemorragias.</p>
                                                <p class="m-0">• Si recientemente se ha sufrido un tratamiento para el acné con isotretinoína (Isoacné®, Dercutane®, Acnemin®, etc.), dado que son tratamientos que modifican la piel y las mucosas, pudiéndose sangrar con mayor facilidad.</p>
                                                <p class="m-0">• Si se tiene epilepsia ya que podría desencadenarse un ataque.</p>
                                                <p class="m-0">• Si se sufren trastornos psicológicos o de la personalidad.</p>
                                                <p><strong>De forma temporal</strong></p>
                                                <p class="m-0">• En caso de sufrir alguna afectación aguda como pueden ser las infecciones locales por verrugas víricas, herpes simple o infecciones bacterianas. Es necesario esperar a que el proceso patológico se resuelva.</p>
                                                <p class="m-0">• Si ha tenido muy recientemente infiltraciones médico estéticas, intervenciones quirúrgicas, quimioterapia o radioterapia, o tiene cicatrices no estabilizadas, quemaduras recientes, úlceras o hematomas. En estos casos habrá que esperar a que la debilidad inmunológica se estabilice.</p>
                                                <p class="m-0">• Situaciones fisiológicas como embarazo o lactancia. Son circunstancias en las que es difícil el tratamiento de las posibles complicaciones por el peligro de transmisión de los agentes que pueden causar efectos no deseables al bebé.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindications for micropigmentation</p>
                                            <div>
                                                <p><strong>Under no circumstances can it be performed</strong></p>
                                                <p class="m-0">• If allergic to pigments.</p>
                                                <p class="m-0">• When the desired area has skin conditions such as freckles, spots, warts, or undiagnosed skin lesions.</p>
                                                <p class="m-0">• If suffering from any skin condition such as psoriasis, hives, etc.</p>
                                                <p><strong>A medical report is recommended</strong></p>
                                                <p class="m-0">• If suffering from infections or diseases such as diabetes, heart or kidney conditions, hepatitis, HIV, etc., as they can decrease defenses and complications can be more severe.</p>
                                                <p class="m-0">• If suffering from blood diseases or undergoing anticoagulant treatment with acenocoumarol (Sintrom®), as they can cause bleeding.</p>
                                                <p class="m-0">• If recently undergoing treatment for acne with isotretinoin (Isoacné®, Dercutane®, Acnemin®, etc.), as they are treatments that modify the skin and mucous membranes, which can bleed more easily.</p>
                                                <p class="m-0">• If suffering from epilepsy as it could trigger a seizure.</p>
                                                <p class="m-0">• If suffering from psychological or personality disorders.</p>
                                                <p><strong>Temporary situations</strong></p>
                                                <p class="m-0">• If suffering from any acute condition such as local infections from viral warts, herpes simplex, or bacterial infections. It is necessary to wait for the pathological process to be resolved.</p>
                                                <p class="m-0">• If having recently undergone medical aesthetic infiltrations, surgical interventions, chemotherapy, or radiotherapy, or having unstable scars, recent burns, ulcers, or hematomas. In these cases, it will be necessary to wait for the immunological weakness to stabilize.</p>
                                                <p class="m-0">• Physiological situations such as pregnancy or lactation. These are circumstances in which the treatment of possible complications is difficult due to the risk of transmitting agents that can cause undesirable effects on the baby.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Contraindicacions micropigmentació</p>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_microblading_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.contraindicaciones_microblading_check == 0">
                                                <p><strong>No es podrà realitzar sota cap circumstància</strong></p>
                                                <p class="m-0">• En cas de ser al·lèrgic als pigments.</p>
                                                <p class="m-0">• Quan la zona on es vol fer té afeccions de la pell com pigues, taques, berrugues, o lesions cutànies no diagnosticades.</p>
                                                <p class="m-0">• Si pateix alguna malaltia de la pell com psoriasi, urticària, etc.</p>
                                                <p><strong>Serà recomanable un informe mèdic</strong></p>
                                                <p class="m-0">• En cas de patir algunes infeccions o malalties com diabetis, afectacions cardíaques o renals, hepatitis, VIH, etc., ja que disminueixen les defenses i les complicacions poden ser més greus.</p>
                                                <p class="m-0">• Si es pateixen malalties de la sang o si s’està fent un tractament anticoagulant amb acenocumarol (Sintrom ®), ja que es poden tenir hemorràgies.</p>
                                                <p class="m-0">• Si recentment s’ha sofert un tractament per a l’acne amb isotretinoína (Isoacné ®, Dercutane ®, Acnemin ®, etc.), donat que són tractaments que modifiquen la pell i les mucoses, i es podria sagnar amb més facilitat.</p>
                                                <p class="m-0">• Si es té epilèpsia ja que podria desencadenar-se un atac.</p>
                                                <p class="m-0">• Si es pateixen trastorns psicològics o de la personalitat.</p>
                                                <p><strong>De forma temporal</strong></p>
                                                <p class="m-0">• En cas de patir alguna afectació aguda com poden ser les infeccions locals per berrugues víriques, herpes simple o infeccions bacterianes. És necessari esperar que el procés patològic es resolgui.</p>
                                                <p class="m-0">• Si ha tingut molt recentment infiltracions medico estètiques, intervencions quirúrgiques, quimioteràpia o radioteràpia, o té cicatrius no estabilitzades, cremades recents, úlceres o hematomes. En aquests casos caldrà esperar que la debilitat immunològica s’estabilitzi.</p>
                                                <p class="m-0">• Situacions fisiològiques com l’embaràs o la lactància. Són circumstàncies en les que és difícil el tractament de les possibles complicacions pel perill de transmissió dels agents que poden causar efectes no desitjables al nadó.</p>
                                            </div>
                                        </div>
                
                                        <!--------------------------------->
                                        <!------RIESGOS SANITARIOS--------->
                                        <!--------------------------------->
                                        <!-- Si español y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riesgos sanitarios del tatuaje</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_tattoo_check == 1" [innerHTML]="trabajo?.estudio.riesgos_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_tattoo_check == 0">
                                                <p class="m-0">• Enfermedades de transmisión sanguínea como hepatitis, tétanos, sida. Pueden ser contagiadas a través del instrumental con el que se realiza el trabajo si no se cuida de una buena práctica higiénica y no se cumple la normativa establecida (material esterilizado, empaquetado individualmente y desechable).</p>
                                                <p class="m-0">• Infecciones locales bacterianas, víricas o fúngicas. Si se realizan prácticas poco asépticas, o no se realizan bien los cuidados durante el proceso de cicatrización.</p>
                                                <p class="m-0">• Alergias en el látex de los guantes del aplicador o en los anestésicos.</p>
                                                <p class="m-0">• Reactivación de herpes simple o herpes zóster.</p>
                                                <p class="m-0">• Mala cicatrización o cicatrices hipertróficas e incluso queloides.</p>    
                                                <p class="m-0">• Alergias que el usuario desconoce que sufre. Son producidas por los componentes de los pigmentos como cromo, níquel, cadmio, óxido de hierro, sales de cobalto, mercurio, óxido de titanio, óxido de zinc etc. El pigmento amarillo puede producir fotodermatitis cuando existe una exposición a los rayos de sol.</p>
                                                <p class="m-0">• Reacciones granulomatosas sarcoides sobre todo por los pigmentos de colores verde, azul y rojo.</p>
                                                <p class="m-0">• En el caso de querer eliminar el tatuaje con las técnicas de láser, debe tenerse en cuenta que la retirada de la tinta de un tatuaje depende de muchos factores como la calidad de la aparatología del láser, número de sesiones, el color de la tinta, la zona del cuerpo, la antigüedad, la profundidad, el tipo de piel y no en todos los casos puede garantizarse la total desaparición. El rayo láser fragmenta las partículas de los pigmentos adheridas a la dermis y epidermis, para reabsorberlas y eliminarlas por tu sistema linfático.</p>
                                            </div>
                                        </div>
                                        <!-- Si ingles y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Risks of tattooing</p>
                                            <div>
                                                <p class="m-0">• Bloodborne diseases such as hepatitis, tetanus, and HIV can be transmitted through unhygienic practices and non-compliance with established regulations (sterilized, individually packaged, and disposable equipment).</p>
                                                <p class="m-0">• Local bacterial, viral, or fungal infections can occur if proper aseptic practices are not followed or if proper care is not taken during the healing process.</p>
                                                <p class="m-0">• Allergies to the latex gloves of the tattoo artist or to the anesthetics.</p>
                                                <p class="m-0">• Reactivation of herpes simplex or shingles.</p>
                                                <p class="m-0">• Poor healing or hypertrophic scarring, and even keloids.</p>
                                                <p class="m-0">• Allergies that the user is unaware of, caused by the components of the pigments, such as chromium, nickel, cadmium, iron oxide, cobalt salts, mercury, titanium oxide, zinc oxide, etc. The yellow pigment can cause photodermatitis when exposed to sunlight.</p>
                                                <p class="m-0">• Sarcoid granulomatous reactions, especially from green, blue, and red pigments.</p>
                                                <p class="m-0">• If you want to remove the tattoo using laser techniques, it should be noted that the removal of tattoo ink depends on many factors such as the quality of the laser equipment, the number of sessions, the color of the ink, the area of the body, the age, the depth, the skin type, and in not all cases can complete disappearance be guaranteed. The laser beam fragments the particles of the pigments adhered to the dermis and epidermis, to absorb and eliminate them through your lymphatic system.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riscos sanitaris del tatuatge</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_tattoo_check == 1" [innerHTML]="trabajo?.estudio.riesgos_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_tattoo_check == 0">
                                                <p class="m-0">• Malalties de transmissió sanguínia com hepatitis, tètanus, sida. Poden ser contagiades a través de l’instrumental amb el que es realitza el treball si no es té cura d’una bona pràctica higiènica i no es compleix la normativa establerta (material esterilitzat, empaquetat individualment i d’un sol ús).</p>
                                                <p class="m-0">• Infeccions locals bacterianes, víriques o fúngiques. Si es realitzen pràctiques poc asèptiques, o no es fan bé les cures durant el procés de cicatrització.</p>
                                                <p class="m-0">• Al·lèrgies al làtex dels guants de l’aplicador o als anestèsics.</p>
                                                <p class="m-0">• Reactivació d’herpes simple o d’herpes zòster.</p>
                                                <p class="m-0">• Mala cicatrització o cicatrius hipertròfiques i fins i tot queloides.</p>    
                                                <p class="m-0">• Al·lèrgies que l’usuari desconeix que pateix. Són produïdes pels components dels pigments com crom, níquel, cadmi, òxid de ferro, sals de cobalt, mercuri, òxid de titani, òxid de zinc etc. El pigment groc pot produir fotodermatitis quan hi ha una exposició als raigs de sol.</p>
                                                <p class="m-0">• Reaccions granulomatoses sarcoides sobretot pels pigments de colors verd, blau i vermell.</p>
                                                <p class="m-0">• En el cas de voler eliminar el tatuatge amb les tècniques de làser, cal tenir en compte que la retirada de la tinta d’un tatuatge depèn de molts factors com ara la qualitat de l’aparatologia del làser, nombre de sessions, el color de la tinta, la zona del cos, l’antiguitat, la profunditat, el tipus de pell i no en tots els casos es pot garantir la total desaparició. El raig làser fragmenta les partícules dels pigments adherides a la dermis i epidermis, per reabsorbir-les i eliminar-les pel teu sistema limfàtic.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riesgos sanitarios del piercing</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_piercing_check == 1" [innerHTML]="trabajo?.estudio.riesgos_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_piercing_check == 0">
                                                <p class="m-0">• Enfermedades de transmisión sanguínea como hepatitis, tétanos, sida. Pueden ser contagiadas a través del instrumental con el que se realiza el trabajo si no se cuida de una buena práctica higiénica y no se cumple la normativa establecida (material esterilizado, empaquetado individualmente y desechable).</p>
                                                <p class="m-0">• Infecciones locales bacterianas, víricas o fúngicas. Si se realizan prácticas poco asépticas, o no se realizan bien los cuidados durante el proceso de cicatrización.</p>
                                                <p class="m-0">• Alergias en el látex de los guantes del aplicador o en los anestésicos.</p>
                                                <p class="m-0">• Reactivación de herpes simple o herpes zóster.</p>
                                                <p class="m-0">• Mala cicatrización o cicatrices hipertróficas e incluso queloides.</p>    
                                                <p class="m-0">• Alergias que el usuario desconoce que sufre. El contenido de níquel en el acero quirúrgico 316L es elevado y puede dar problemas alérgicos, así como el níquel contenido en otras aleaciones.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Health risks of piercing</p>
                                            <div>
                                                <p class="m-0">• Bloodborne diseases such as hepatitis, tetanus, and HIV. These can be transmitted through the equipment used during the procedure if good hygiene practices and established regulations (sterilized, individually packaged and disposable material) are not followed.</p>
                                                <p class="m-0">• Local bacterial, viral, or fungal infections. If unhygienic practices are carried out or if proper care is not taken during the healing process.</p>
                                                <p class="m-0">• Allergies to the latex gloves of the applicator or anesthetics.</p>
                                                <p class="m-0">• Reactivation of cold sores or shingles.</p>
                                                <p class="m-0">• Poor healing or hypertrophic scarring, and even keloids.</p>
                                                <p class="m-0">• Allergies that the user is unaware of. The nickel content in surgical steel 316L is high and can cause allergic problems, as can nickel content in other alloys.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riscos sanitaris del pírcing</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_piercing_check == 1" [innerHTML]="trabajo?.estudio.riesgos_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_piercing_check == 0">
                                                <p class="m-0">• Malalties de transmissió sanguínia com hepatitis, tètanus, sida. Poden ser contagiades a través de l’instrumental amb el que es realitza el treball si no es té cura d’una bona pràctica higiènica i no es compleix la normativa establerta (material esterilitzat, empaquetat individualment i d’un sol ús).</p>
                                                <p class="m-0">• Infeccions locals bacterianes, víriques o fúngiques. Si es realitzen pràctiques poc asèptiques, o no es fan bé les cures durant el procés de cicatrització.</p>
                                                <p class="m-0">• Al·lèrgies al làtex dels guants de l’aplicador o als anestèsics.</p>
                                                <p class="m-0">• Reactivació d’herpes simple o d’herpes zòster.</p>
                                                <p class="m-0">• Mala cicatrització o cicatrius hipertròfiques i fins i tot queloides.</p>    
                                                <p class="m-0">• Al·lèrgies que l’usuari desconeix que pateix. El contingut de níquel en l’acer quirúrgic 316L és elevat i pot donar problemes al·lèrgics, així com el níquel contingut en altres aliatges.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riesgos sanitarios de la micropigmentación</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_microblading_check == 1" [innerHTML]="trabajo?.estudio.riesgos_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_microblading_check == 0">
                                                <p class="m-0">• Enfermedades de transmisión sanguínea como hepatitis, tétanos, sida. Pueden ser contagiadas a través del instrumental con el que se realiza el trabajo si no se cuida de una buena práctica higiénica y no se cumple la normativa establecida (material esterilizado, empaquetado individualmente y desechable).</p>
                                                <p class="m-0">• Infecciones locales bacterianas, víricas o fúngicas. Si se realizan prácticas poco asépticas, o no se realizan bien los cuidados durante el proceso de cicatrización.</p>
                                                <p class="m-0">• Alergias en el látex de los guantes del aplicador o en los anestésicos.</p>
                                                <p class="m-0">• Reactivación de herpes simple o herpes zóster.</p>
                                                <p class="m-0">• Mala cicatrización o cicatrices hipertróficas e incluso queloides.</p>    
                                                <p class="m-0">• Alergias que el usuario desconoce que sufre. Son producidas por los componentes de los pigmentos como cromo, níquel, cadmio, óxido de hierro, sales de cobalto, mercurio, óxido de titanio, óxido de zinc etc. El pigmento amarillo puede producir fotodermatitis cuando existe una exposición a los rayos de sol.</p>
                                                <p class="m-0">• Reacciones granulomatosas sarcoides sobre todo por los pigmentos de colores verde, azul y rojo.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Health risks of micropigmentation</p>
                                            <div>
                                                <p class="m-0">• Bloodborne diseases such as hepatitis, tetanus, and HIV can be transmitted through non-sterile equipment and poor hygiene practices that do not follow established regulations (sterilized, individually packaged, and disposable materials).</p>
                                                <p class="m-0">• Local bacterial, viral, or fungal infections may occur due to poor hygiene practices or inadequate aftercare during the healing process.</p>
                                                <p class="m-0">• Allergies to latex gloves or anesthetics.</p>
                                                <p class="m-0">• Reactivation of herpes simplex or herpes zoster.</p>
                                                <p class="m-0">• Poor healing or hypertrophic and even keloid scars.</p>
                                                <p class="m-0">• Allergies that the user is unaware of. They are produced by components of pigments such as chromium, nickel, cadmium, iron oxide, cobalt salts, mercury, titanium oxide, zinc oxide, etc. Yellow pigment may cause photodermatitis when exposed to sunlight.</p>
                                                <p class="m-0">• Sarcoid granulomatous reactions, especially from green, blue, and red pigments.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Riscos sanitaris de la micropigmentació</p>
                                            <div *ngIf="trabajo?.estudio.riesgos_microblading_check == 1" [innerHTML]="trabajo?.estudio.riesgos_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.riesgos_microblading_check == 0">
                                                <p class="m-0">• Malalties de transmissió sanguínia com hepatitis, tètanus, sida. Poden ser contagiades a través de l’instrumental amb el que es realitza el treball si no es té cura d’una bona pràctica higiènica i no es compleix la normativa establerta (material esterilitzat, empaquetat individualment i d’un sol ús).</p>
                                                <p class="m-0">• Infeccions locals bacterianes, víriques o fúngiques. Si es realitzen pràctiques poc asèptiques, o no es fan bé les cures durant el procés de cicatrització.</p>
                                                <p class="m-0">• Al·lèrgies al làtex dels guants de l’aplicador o als anestèsics.</p>
                                                <p class="m-0">• Reactivació d’herpes simple o d’herpes zòster.</p>
                                                <p class="m-0">• Mala cicatrització o cicatrius hipertròfiques i fins i tot queloides.</p>    
                                                <p class="m-0">• Al·lèrgies que l’usuari desconeix que pateix. Són produïdes pels components dels pigments com crom, níquel, cadmi, òxid de ferro, sals de cobalt, mercuri, òxid de titani, òxid de zinc etc. El pigment groc pot produir fotodermatitis quan hi ha una exposició als raigs de sol.</p>
                                                <p class="m-0">• Reaccions granulomatoses sarcoides sobretot pels pigments de colors verd, blau i vermell.</p>
                                            </div>
                                        </div>
                
                                        <!--------------------------------->
                                        <!------INFORMACION PREVIA--------->
                                        <!--------------------------------->
                                        <!-- Si español y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Indicaciones previas a la realización del tatuaje</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_tattoo_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_tattoo_check == 0">
                                                <p class="m-0">• La época del año menos adecuada para hacerlo es el verano, por el sudor y las prácticas asociadas de playa, piscina y exposición al sol o rayos uva.</p>
                                                <p class="m-0">• Pensar bien la zona donde se va a colocar.</p>
                                                <p class="m-0">• En caso de pruebas diagnósticas, como resonancia magnética, deberá hacerlo constar.</p>
                                                <p class="m-0">• Si es donante de sangre, esta práctica limitará un tiempo la donación. Igualmente deberá hacerlo constar.</p>
                                                <p class="m-0">• Los días previos se recomienda no consumir drogas, alcohol, medicamentos antiplaquetarios como el ácido acetilsalicílico (aspirina ®, etc.), ya que estos productos pueden aumentar el riesgo de hemorragia.</p>
                                                <p class="m-0">• Es preferible no estar en ayunas.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Pre-tattoo instructions</p>
                                            <div>
                                                <p class="m-0">• The least suitable time of year to get a tattoo is during the summer, due to sweating and associated activities such as going to the beach, swimming pools, and exposure to the sun or UV rays.</p>
                                                <p class="m-0">• Carefully consider the location where you want the tattoo to be placed.</p>
                                                <p class="m-0">• If you have any diagnostic tests, such as a magnetic resonance imaging (MRI) scan, you must inform the medical staff about your tattoo.</p>
                                                <p class="m-0">• If you are a blood donor, getting a tattoo may limit blood donation for a certain period of time. You must inform the blood bank staff about your tattoo.</p>
                                                <p class="m-0">• It is recommended to avoid drugs, alcohol, and antiplatelet medications such as acetylsalicylic acid (aspirin ®, etc.) in the days leading up to the tattoo, as these products can increase the risk of bleeding.</p>
                                                <p class="m-0">• It is preferable not to be fasting. </p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informacions prèvies a la pràctica del tatuatge</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_tattoo_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_tattoo_check == 0">
                                                <p class="m-0">• L’època de l’any menys adient per fer-ho és l’estiu, per la suor i les pràctiques associades de platja, piscina i exposició al sol o raigs uva.</p>
                                                <p class="m-0">• Pensar bé la zona on es vol col·locar.</p>
                                                <p class="m-0">• En cas de proves diagnòstiques, com una ressonància magnètica, haurà de fer-ho constar.</p>
                                                <p class="m-0">• Si és donant de sang, aquesta pràctica limitarà un temps la donació. Igualment ho haurà de fer constar.</p>
                                                <p class="m-0">• Els dies abans de fer-se el pírcing es recomana no consumir drogues, alcohol, medicaments antiplaquetaris com l’àcid acetilsalicílic (aspirina ®, etc.), ja que aquests productes poden augmentar el risc d’hemorràgia.</p>
                                                <p class="m-0">• És preferible no estar en dejú.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Indicaciones previas a la realización del piercing</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_piercing_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_piercing_check == 0">
                                                <p class="m-0">• La época del año menos adecuada para hacerlo es el verano, por el sudor y las prácticas asociadas de playa, piscina y exposición al sol o rayos uva.</p>
                                                <p class="m-0">• Pensar bien la zona donde se va a colocar.</p>
                                                <p class="m-0">• En caso de pruebas diagnósticas, como resonancia magnética, deberá hacerlo constar.</p>
                                                <p class="m-0">• Si es donante de sangre, esta práctica limitará un tiempo la donación. Igualmente deberá hacerlo constar.</p>
                                                <p class="m-0">• Los días previos se recomienda no consumir drogas, alcohol, medicamentos antiplaquetarios como el ácido acetilsalicílico (aspirina ®, etc.), ya que estos productos pueden aumentar el riesgo de hemorragia.</p>
                                                <p class="m-0">• Es preferible no estar en ayunas.</p>
                                            </div>
                                        </div>
                                        <!-- Si ingles y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Indications prior to getting a piercing</p>
                                            <div>
                                                <p class="m-0">• The least suitable time of year to get a piercing is during summer due to sweat and associated activities such as going to the beach, swimming and exposure to the sun or UV rays.</p>
                                                <p class="m-0">• Carefully consider the location where the piercing will be placed.</p>
                                                <p class="m-0">• If you need diagnostic tests, such as magnetic resonance imaging (MRI), you must notify the medical staff about your piercing.</p>
                                                <p class="m-0">• If you are a blood donor, getting a piercing will limit your ability to donate blood for a period of time. You must also notify the blood donation center about your piercing.</p>
                                                <p class="m-0">• In the days leading up to the piercing, it is recommended to avoid drugs, alcohol, anti-platelet medications such as aspirin ®, etc., as these products can increase the risk of bleeding.</p>
                                                <p class="m-0">• It is preferable not to be fasting.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informacions prèvies a la pràctica del pírcing</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_piercing_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_piercing_check == 0">
                                                <p class="m-0">• L’època de l’any menys adient per fer-ho és l’estiu, per la suor i les pràctiques associades de platja, piscina i exposició al sol o raigs uva.</p>
                                                <p class="m-0">• Pensar bé la zona on es vol col·locar.</p>
                                                <p class="m-0">• En cas de proves diagnòstiques, com una ressonància magnètica, haurà de fer-ho constar.</p>
                                                <p class="m-0">• Si és donant de sang, aquesta pràctica limitarà un temps la donació. Igualment ho haurà de fer constar.</p>
                                                <p class="m-0">• Els dies abans de fer-se el pírcing es recomana no consumir drogues, alcohol, medicaments antiplaquetaris com l’àcid acetilsalicílic (aspirina ®, etc.), ja que aquests productes poden augmentar el risc d’hemorràgia.</p>
                                                <p class="m-0">• És preferible no estar en dejú.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Indicaciones previas a la realización de la micropigmentación</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_microblading_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_microblading_check == 0">
                                                <p class="m-0">• La época del año menos adecuada para hacerlo es el verano, por el sudor y las prácticas asociadas de playa, piscina y exposición al sol o rayos uva.</p>
                                                <p class="m-0">• Pensar bien la zona donde se va a colocar.</p>
                                                <p class="m-0">• Pueden producirse otros efectos leves como hinchazón, dolor, microhematomas en la zona de aplicación.</p>
                                                <p class="m-0">• Puede haber migración de los pigmentos.</p>
                                                <p class="m-0">• Complicaciones estéticas sin repercusión clínica: dispersión del pigmento por una introducción demasiado profunda, eliminación del pigmento por una introducción superficial, disminución de la calidad del pigmento por causas de índole personal, reacciones granulomatosas... etc</p>
                                                <p class="m-0">• No exponerse al sol ni a rayos uva, ni realizar tratamientos faciales.</p>
                                                <p class="m-0">• En caso de pruebas diagnósticas, como resonancia magnética, deberá hacerlo constar.</p>
                                                <p class="m-0">• Si es donante de sangre, esta práctica limitará un tiempo la donación. Igualmente deberá hacerlo constar.</p>
                                                <p class="m-0">• Los días previos se recomienda no consumir drogas, alcohol, medicamentos antiplaquetarios como el ácido acetilsalicílico (aspirina ®, etc.), ya que estos productos pueden aumentar el riesgo de hemorragia.</p>
                                                <p class="m-0">• Es preferible no estar en ayunas.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y micropigmentacion -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Indications prior to micropigmentation</p>
                                            <div>
                                                <p class="m-0">• The least suitable time of year to do it is during the summer, due to sweating and associated activities like beach, pool, and exposure to the sun or UV rays.</p>
                                                <p class="m-0">• Think carefully about the area where it will be placed.</p>
                                                <p class="m-0">• Other mild effects may occur such as swelling, pain, microhematomas in the application area.</p>
                                                <p class="m-0">• Pigment migration may occur.</p>
                                                <p class="m-0">• Aesthetic complications without clinical repercussions: pigment dispersion due to excessive depth of insertion, pigment elimination due to superficial insertion, decreased pigment quality due to personal factors, granulomatous reactions, etc.</p>
                                                <p class="m-0">• Do not expose yourself to the sun or UV rays, or perform facial treatments.</p>
                                                <p class="m-0">• If you have diagnostic tests such as magnetic resonance imaging, you should make it known.</p>
                                                <p class="m-0">• If you are a blood donor, this practice will limit donation for a period of time. You should also make this known.</p>
                                                <p class="m-0">• In the days leading up to the procedure, it is recommended to avoid drugs, alcohol, antiplatelet medications such as aspirin®, etc., as these products can increase the risk of bleeding.</p>
                                                <p class="m-0">• It is preferable not to be fasting.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y micropigmentación -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informacions prèvies a la pràctica la micropigmentació</p>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_microblading_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.indicaciones_previas_microblading_check == 0">
                                                <p class="m-0">• L’època de l’any menys adient per fer-ho és l’estiu, per la suor i les pràctiques associades de platja, piscina i exposició al sol o raigs uva.</p>
                                                <p class="m-0">• Poden produir-se altres efectes lleus com inflor, dolor, microhematomes a la zona d’aplicació.</p>
                                                <p class="m-0">• Pot haver-hi migració dels pigments.</p>
                                                <p class="m-0">• Complicacions estètiques sense repercussió clínica: dispersió del pigment per una introducció massa profunda. Eliminació del pigment per una introducció superficial. Disminució de la qualitat del pigment per causes d’índole personal. Reaccions granulomatoses... etc</p>
                                                <p class="m-0">• No exposar-se al sol ni al raigs uva, ni realitzar tractaments facials..</p>
                                                <p class="m-0">• En cas de proves diagnòstiques, com una ressonància magnètica, haurà de fer-ho constar.</p>
                                                <p class="m-0">• Si és donant de sang, aquesta pràctica limitarà un temps la donació. Igualment ho haurà de fer constar.</p>
                                                <p class="m-0">• Els dies abans de fer-se el pírcing es recomana no consumir drogues, alcohol, medicaments antiplaquetaris com l’àcid acetilsalicílic (aspirina ®, etc.), ja que aquests productes poden augmentar el risc d’hemorràgia.</p>
                                                <p class="m-0">• És preferible no estar en dejú.</p>
                                            </div>
                                        </div>
                
                                        <!--------------------------------->
                                        <!------CUIDADOS POSTERIOR--------->
                                        <!--------------------------------->
                                        <!-- Si español y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consejos y cuidados post-tatuaje</p>
                                            <p class="m-0"><strong><u>Recuerda seguir siempre las indicaciones y recomendaciones del aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_tattoo_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_tattoo_check == 0">
                                                <p class="m-0">• Lavarse bien las manos antes de tocar la zona afectada.</p>
                                                <p class="m-0">• Mantener la zona seca.</p>
                                                <p class="m-0">• No agredir la zona afectada: no rascarla, ni frotarla. Si aparece costra no debe rascarse ni quitarse.</p>
                                                <p class="m-0">• Evitar cosméticos no específicos: cremas, maquillajes, leches limpiadoras, vaselina, etc.</p>
                                                <p class="m-0">• Evitar la exposición al sol ya los rayos uva. En cualquier caso utilizar protectores solares de pantalla total.</p>
                                                <p class="m-0">• Utilizar ropa que no apriete en la zona donde se ha hecho.</p>
                                                <p class="m-0">• Hasta que no esté cicatrizado no bañarse en la piscina ni en la playa y no utilizar la sauna.</p>
                                                <p class="m-0">En caso de sufrir enfermedades o desconocer su historial médico debe consultar al médico. Si durante el proceso de post tratamiento apareciera cualquier reacción o alteración debe consultar al médico.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Aftercare tips and advice for tattoos</p>
                                            <p class="m-0"><strong><u>Remember to always follow the instructions and recommendations of the artist.</u></strong></p>
                                            <div>
                                                <p class="m-0">• Wash your hands thoroughly before touching the affected area.</p>
                                                <p class="m-0">• Keep the area dry.</p>
                                                <p class="m-0">• Do not aggravate the affected area: do not scratch or rub it. If scabbing occurs, do not scratch or pick it off.</p>
                                                <p class="m-0">• Avoid non-specific cosmetics such as creams, make-up, cleansers, petroleum jelly, etc.</p>
                                                <p class="m-0">• Avoid exposure to the sun and UV rays. In any case, use sunscreen with total screen protection.</p>
                                                <p class="m-0">• Wear loose clothing in the area where the tattoo was done.</p>
                                                <p class="m-0">• Until it is fully healed, do not swim in the pool or beach or use the sauna.</p>
                                                <p class="m-0">If you have any medical conditions or are unsure of your medical history, consult your doctor. If any reaction or alteration appears during the aftercare process, consult your doctor. </p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y tattoo -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && (trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2)">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consells per al tractament de la zona afectada pel tatuatge</p>
                                            <p class="m-0"><strong><u>Recordeu seguir sempre les indicacions i recomanacions de l'aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_tattoo_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_tattoo_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_tattoo_check == 0">
                                                <p class="m-0">• Rentar-se bé les mans abans de tocar la zona afectada.</p>
                                                <p class="m-0">• Mantenir la zona seca.</p>
                                                <p class="m-0">• No agredir-la: no rascar-la, ni fregar-la. Si apareix crosta no s’ha de rascar ni treure.</p>
                                                <p class="m-0">• Evitar cosmètics no específics: cremes, maquillatges, llets netejadores, vaselina, etc.</p>
                                                <p class="m-0">• Evitar l’exposició al sol i als raigs uva. En tot cas utilitzar protectors solars de pantalla total.</p>
                                                <p class="m-0">• Utilitzar roba que no estrenyi a la zona on s’ha fet.</p>
                                                <p class="m-0">• Fins que no estigui cicatritzat no banyar-se a la piscina ni a la platja i no utilitzar la sauna.</p>
                                                <p class="m-0">En cas de patir malalties o desconèixer el seu historial mèdic ha de consultar el metge. Si durant el procés de post tractament aparegués qualsevol reacció o alteració ha de consultar el metge.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consejos y cuidados post-piercing</p>
                                            <p class="m-0"><strong><u>Recuerda seguir siempre las indicaciones y recomendaciones del aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_piercing_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_piercing_check == 0">
                                                <p class="m-0">• Lavarse bien las manos antes de tocar la zona afectada.</p>
                                                <p class="m-0">• Hacer la higiene diaria con suero fisiológico y aplicado con una gasa estéril.</p>
                                                <p class="m-0">• Aplicar frío seco (los dos primeros días, máximo 5 minutos consecutivos).</p>
                                                <p class="m-0">• Mantener la zona seca.</p>
                                                <p class="m-0">• No agredir la zona afectada: no rascarla, ni frotarla. Si aparece costra no debe rascarse ni quitarse.</p>
                                                <p class="m-0">• Evitar cosméticos no específicos: cremas, maquillajes, leches limpiadoras, vaselina, etc.</p>
                                                <p class="m-0">• Utilizar ropa que no apriete en la zona donde se ha hecho.</p>
                                                <p class="m-0">• Hasta que no esté cicatrizado no bañarse en la piscina ni en la playa y no utilizar la sauna.</p>
                                                <p class="m-0">En caso de sufrir enfermedades o desconocer su historial médico debe consultar al médico. Si durante el proceso de post tratamiento apareciera cualquier reacción o alteración debe consultar al médico.</p>
                                            </div>
                                        </div>
                                        <!-- Si ingles y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Post-piercing tips and care</p>
                                            <p class="m-0"><strong><u>Remember to always follow the instructions and recommendations of the piercer.</u></strong></p>
                                            <div>
                                                <p class="m-0">• Wash your hands well before touching the affected area.</p>
                                                <p class="m-0">• Perform daily hygiene with saline solution applied with a sterile gauze.</p>
                                                <p class="m-0">• Apply dry cold (for the first two days, a maximum of 5 consecutive minutes).</p>
                                                <p class="m-0">• Keep the area dry.</p>
                                                <p class="m-0">• Do not agitate the affected area: do not scratch or rub it. If a scab appears, it should not be scratched or removed.</p>
                                                <p class="m-0">• Avoid non-specific cosmetics: creams, makeup, cleansing milks, petroleum jelly, etc.</p>
                                                <p class="m-0">• Wear loose clothing in the area where the piercing was done.</p>
                                                <p class="m-0">• Until it is healed, do not bathe in the pool or at the beach and do not use the sauna.</p>
                                                <p class="m-0">If you have any illnesses or do not know your medical history, consult your doctor. If any reaction or alteration appears during the post-treatment process, consult your doctor.</p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y piercing -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 3">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consells per al tractament de la zona afectada pel pírcing</p>
                                            <p class="m-0"><strong><u>Recordeu seguir sempre les indicacions i recomanacions de l'aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_piercing_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_piercing_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_piercing_check == 0">
                                                <p class="m-0">• Rentar-se bé les mans abans de tocar la zona afectada.</p>
                                                <p class="m-0">• Hacer la higiene diaria con suero fisiológico y aplicado con una gasa estéril.</p>
                                                <p class="m-0">• Aplicar frío seco (los dos primeros días, máximo 5 minutos consecutivos).</p>
                                                <p class="m-0">• Mantenir la zona seca.</p>
                                                <p class="m-0">• No agredir-la: no rascar-la, ni fregar-la. Si apareix crosta no s’ha de rascar ni treure.</p>
                                                <p class="m-0">• Evitar cosmètics no específics: cremes, maquillatges, llets netejadores, vaselina, etc.</p>
                                                <p class="m-0">• Utilitzar roba que no estrenyi a la zona on s’ha fet.</p>
                                                <p class="m-0">• Fins que no estigui cicatritzat no banyar-se a la piscina ni a la platja i no utilitzar la sauna.</p>
                                                <p class="m-0">En cas de patir malalties o desconèixer el seu historial mèdic ha de consultar el metge. Si durant el procés de post tractament aparegués qualsevol reacció o alteració ha de consultar el metge.</p>
                                            </div>
                                        </div>
                                        <!-- Si español y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consejos y cuidados post-micropigmentacion</p>
                                            <p class="m-0"><strong><u>Recuerda seguir siempre las indicaciones y recomendaciones del aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_microblading_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_microblading_check == 0">
                                                <p class="m-0">• Lavarse bien las manos antes de tocar la zona afectada.</p>
                                                <p class="m-0">• Mantener la zona seca.</p>
                                                <p class="m-0">• No agredir la zona afectada: no rascarla, ni frotarla. Si aparece costra no debe rascarse ni quitarse.</p>
                                                <p class="m-0">• Evitar cosméticos no específicos: cremas, maquillajes, leches limpiadoras, vaselina, etc.</p>
                                                <p class="m-0">• Evitar la exposición al sol ya los rayos uva. En cualquier caso utilizar protectores solares de pantalla total.</p>
                                                <p class="m-0">• Hasta que no esté cicatrizado no bañarse en la piscina ni en la playa y no utilizar la sauna.</p>
                                                <p class="m-0">En caso de sufrir enfermedades o desconocer su historial médico debe consultar al médico. Si durante el proceso de post tratamiento apareciera cualquier reacción o alteración debe consultar al médico.</p>
                                            </div>
                                        </div>
                                        <!-- Si inglés y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Post-Micropigmentation Care and Advice</p>
                                            <p class="m-0"><strong><u>Remember to always follow the indications and recommendations of the technician.</u></strong></p>
                                            <div>
                                                <p class="m-0">• Wash your hands thoroughly before touching the affected area.</p>
                                                <p class="m-0">• Keep the area dry.</p>
                                                <p class="m-0">• Do not agitate the affected area: do not scratch or rub it. If scabs appear, do not scratch or remove them.</p>
                                                <p class="m-0">• Avoid non-specific cosmetics: creams, make-up, cleansing milks, vaseline, etc.</p>
                                                <p class="m-0">• Avoid exposure to the sun and UV rays. In any case, use total screen sunscreens.</p>
                                                <p class="m-0">• Until it is healed, do not bathe in the pool or beach, and do not use the sauna.</p>
                                                <p class="m-0">If you have any illnesses or do not know your medical history, you should consult a doctor. If any reaction or alteration appears during the post-treatment process, you should consult a doctor. </p>
                                            </div>
                                        </div>
                                        <!-- Si catalán y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 5">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Consells per al tractament de la zona afectada per la micropigmentació</p>
                                            <p class="m-0"><strong><u>Recordeu seguir sempre les indicacions i recomanacions de l'aplicador.</u></strong></p>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_microblading_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_microblading_custom"></div>
                                            <div *ngIf="trabajo?.estudio.cuidados_posteriores_microblading_check == 0">
                                                <p class="m-0">• Rentar-se bé les mans abans de tocar la zona afectada.</p>
                                                <p class="m-0">• Mantenir la zona seca.</p>
                                                <p class="m-0">• No agredir-la: no rascar-la, ni fregar-la. Si apareix crosta no s’ha de rascar ni treure.</p>
                                                <p class="m-0">• Evitar cosmètics no específics: cremes, maquillatges, llets netejadores, vaselina, etc.</p>
                                                <p class="m-0">• Evitar l’exposició al sol i als raigs uva. En tot cas utilitzar protectors solars de pantalla total.</p>
                                                <p class="m-0">• Fins que no estigui cicatritzat no banyar-se a la piscina ni a la platja i no utilitzar la sauna.</p>
                                                <p class="m-0">En cas de patir malalties o desconèixer el seu historial mèdic ha de consultar el metge. Si durant el procés de post tractament aparegués qualsevol reacció o alteració ha de consultar el metge.</p>
                                            </div>
                                        </div>
                                        
                                        <!-------------------------------------------------->
                                        <!---------INFO ADICIONAL MICROPIGMENTACION--------->
                                        <!-------------------------------------------------->
                                        <!-- Si español y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 5 && trabajo?.estudio.info_adicional_microblading_check == 1">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información adicional</p>
                                            <div [innerHTML]="trabajo?.estudio.info_adicional_microblading_custom"></div>
                                        </div>
                                        <!-- Si ingles y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en' && trabajo?.tipo_proyecto == 5 && trabajo?.estudio.info_adicional_microblading_check == 1">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Additional information</p>
                                            <div [innerHTML]="trabajo?.estudio.info_adicional_microblading_custom"></div>
                                        </div>
                                        <!-- Si catalán y micro -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 5 && trabajo?.estudio.info_adicional_microblading_check == 1">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació addicional</p>
                                            <div [innerHTML]="trabajo?.estudio.info_adicional_microblading_custom"></div>
                                        </div>



                                        <!-------------------------------------------------->
                                        <!-------------------ESTETICA----------------------->
                                        <!-------------------------------------------------->
                                        <!-- Si español y ESTETICA - GEMAS DENTALES -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 31">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información sobre la práctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_gemasdentales"></div>
                                        </div>
                                        <!-- Si catalán y ESTETICA - GEMAS DENTALES -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 31">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació sobre la pràctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_gemasdentales"></div>
                                        </div>
                                        <!-- Si español y ESTETICA - DEPILACION FACIAL -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 28">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información sobre la práctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_depilacionfacial"></div>
                                        </div>
                                        <!-- Si catalán y ESTETICA - DEPILACION FACIAL -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 28">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació sobre la pràctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_depilacionfacial"></div>
                                        </div>
                                        <!-- Si español y ESTETICA - LIFTING PESTAÑAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 29">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información sobre la práctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_liftingpest"></div>
                                        </div>
                                        <!-- Si catalán y ESTETICA - LIFTING PESTAÑAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 29">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació sobre la pràctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_liftingpest"></div>
                                        </div>
                                        <!-- Si español y ESTETICA - LAMINADO DE CEJAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información sobre la práctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_laminadocejas"></div>
                                        </div>
                                        <!-- Si catalán y ESTETICA - LAMINADO DE CEJAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació sobre la pràctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_laminadocejas"></div>
                                        </div>
                                        <!-- Si español y ESTETICA - EXTENSIÓN DE PESTAÑAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Información sobre la práctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_extpestanas"></div>
                                        </div>
                                        <!-- Si catalán y ESTETICA - EXTENSIÓN DE PESTAÑAS -->
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.tipo_proyecto == 6 && trabajo?.fase_cita == 30">
                                            <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2">Informació sobre la pràctica</p>
                                            <div [innerHTML]="trabajo?.estudio.info_extpestanas"></div>
                                        </div>



                                    </div>
                                    
                                    <div class="col-md-12 border border-dark mb-3 p-3" *ngIf="trabajo?.tipo_proyecto == 4">
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'ca'">CONSENTIMENT INFORMAT D'ELIMINACIÓ DE TATUATGES AMB LÀSER</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'es'">CONSENTIMIENTO INFORMADO DE ELIMINACIÓN DE TATUAJES CON LÁSER</h5>
                                        <h5 class="text-dark font-weight-bolder" *ngIf="trabajo?.idioma_consentimiento == 'en'">INFORMED CONSENT FOR TATTOO REMOVAL WITH LASER</h5>
                                        <hr class="mt-0 mb-2">
                                        <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'"><strong>El tractament consisteix a destruir controladament el pigment del tatuatge mitjançant l'ús d'una llum làser. El feix de làser té una especial afinitat per pigments vermells, verds o negres, produint un mínim dany al teixit adjacent. La tècnica està indicada en tatuatges artístics realitzats a qualsevol zona de la pell o mucoses.</strong></p>
                                        <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'"><strong>El tratamiento consiste en destruir de forma controlada el pigmento del tatuaje mediante el uso de una luz láser. El haz de láser tiene una especial afinidad por pigmentos rojos, verdes o negros, produciendo un mínimo daño en el tejido adyacente. La técnica está indicada en tatuajes artísticos realizados en cualquier zona de la piel o mucosas.</strong></p>
                                        <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'en'"><strong>The treatment consists of controlled destruction of the tattoo pigment using a laser light. The laser beam has a special affinity for red, green or black pigments, producing minimal damage to adjacent tissue. The technique is indicated for artistic tattoos made in any area of the skin or mucous membranes.</strong></p>
                
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.estudio.contraindicaciones_laser_check == 1">Contraindicacions</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.estudio.contraindicaciones_laser_check == 1">Contraindicaciones</p>
                                        <div *ngIf="trabajo?.estudio.contraindicaciones_laser_check == 1" [innerHTML]="trabajo?.estudio.contraindicaciones_laser_custom"></div>
                
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.estudio.riesgos_laser_check == 1">Riscos sanitaris</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.estudio.riesgos_laser_check == 1">Riegos sanitarios</p>
                                        <div *ngIf="trabajo?.estudio.riesgos_laser_check == 1" [innerHTML]="trabajo?.estudio.riesgos_laser_custom"></div>
                
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.estudio.indicaciones_previas_laser_check == 1">Informacions prèvies a la pràctica</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.estudio.indicaciones_previas_laser_check == 1">Informaciones previas a la práctica</p>
                                        <div *ngIf="trabajo?.estudio.indicaciones_previas_laser_check == 1" [innerHTML]="trabajo?.estudio.indicaciones_previas_laser_custom"></div>
                
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'ca' && trabajo?.estudio.cuidados_posteriores_laser_check == 1">Consells per al tractament de la zona afectada</p>
                                        <p class="bg-secondary text-white d-inline-block px-2 font-size-15 font-weight-bold mt-2 mb-2" *ngIf="trabajo?.idioma_consentimiento == 'es' && trabajo?.estudio.cuidados_posteriores_laser_check == 1">Consejos para el tratamiento de la zona afectada</p>
                                        <div *ngIf="trabajo?.estudio.cuidados_posteriores_laser_check == 1" [innerHTML]="trabajo?.estudio.cuidados_posteriores_laser_custom"></div>
                
                                        <div *ngIf="trabajo?.estudio.contraindicaciones_laser_check == 0 && trabajo?.estudio.riesgos_laser_check == 0 && trabajo?.estudio.indicaciones_previas_laser_check == 0 && trabajo?.estudio.cuidados_posteriores_laser_check == 0">
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· He estat informat amb llenguatge clar i senzill sobre tots els dubtes que se m'han plantejat sobre la tècnica i el tractament de l'eliminació làser de tatuatges.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· Que el làser picosegons és l'opció més avançada en aquest moment en l'eliminació de tatuatges i la realitza un tècnic especialitzat.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· El nombre de sessions necessàries per eliminar totalment un tatuatge pot ser variable en cada persona ja que depèn de factors com, anys del tatuatge, qualitat de la tinta, profunditat a què s'ha tatuat o cicatrització i sistema limfàtic de cada persona.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· És normal que després de la sessió de làser aparegui una inflamació i una sensació de coïssor que pot durar entre un i diversos dies i/o aparició de butllofes o crostes no havent d'arrencar ni explotar en cap moment per evitar marques a la pell.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· L'interval entre sessions ha de ser d'un mínim de dos mesos, encara que de vegades pot ser aconsellable distanciar-les més.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· Que tot i ser poc freqüent el làser pot produir canvis de pigmentació de la pell a la zona tractada i encara que aquests curen en uns mesos, excepcionalment podrien ser permanents.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· Poden aparèixer cicatrius o marques a la pell derivades d'un mal procediment a l'hora de tatuar o un mal procediment a l'hora de guarir per part del client.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· Em comprometo a fer les cures del tractament seguint fidelment totes les instruccions del tècnic làser.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'ca'">· Autoritzo que la sessió pugui ser filmada o fotografiada amb finalitats publicitàries i/o seguiment del tractament d'eliminació làser de tatuatges tant per nosaltres com pels nostres centres col·laboradors.</p>
                
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· He sido informado con lenguaje claro y sencillo acerca de todas las dudas que se me han planteado sobre la técnica y el tratamiento de la eliminación láser de tatuajes.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Que el láser picosegundos es la opción mas avanzada en este momento en la eliminación de tatuajes y la realiza un técnico especializado.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· El número de sesiones necesarias para eliminar totalmente un tatuaje puede ser variable en cada persona ya que depende de factores como, años del tatuaje, calidad de la tinta, profundidad a la que se ha tatuado o cicatrización y sistema linfático de cada persona.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Es normal que tras la sesión de láser aparezca una inflamación y una sensación de escozor que puede durar entre uno y varios días y/o aparición de ampollas o costras no debiéndose arrancar ni explotar en ningún momento para evitar marcas en la piel.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· El intervalo entre sesiones debe ser de un mínimo de dos meses aunque en ocasiones puede ser aconsejable distanciarlas más.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Que aun siendo poco frecuente el láser puede producir cambios de pigmentación de la piel en la zona tratada y aunque estos curan en unos meses, excepcionalmente podrían ser permanentes.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Pueden aparecer cicatrices o marcas en la piel derivadas de un mal procedimiento a la hora de tatuar o un mal procedimiento a la hora de curar por parte del cliente.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Me comprometo a realizar las curas del tratamiento siguiendo fielmente todas las instrucciones del técnico láser.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Autorizo que la sesión pueda ser filmada o fotografiada con fines publicitarios y/o seguimiento del tratamiento de eliminación láser de tatuajes tanto por nosotros como por nuestros centros colaboradores.</p>
                                        </div>
                                        <div *ngIf="trabajo?.idioma_consentimiento == 'en'">
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· I have been informed in clear and simple language about all the questions that have been raised about the technique and treatment of laser tattoo removal.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· That picosecond laser is the most advanced option at this time for tattoo removal and it is performed by a specialized technician.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· The number of sessions necessary to completely remove a tattoo may vary in each person since it depends on factors such as the age of the tattoo, quality of the ink, depth at which it was tattooed, or individual lymphatic and healing systems.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· It is normal for inflammation and a burning sensation to appear after the laser session, which can last from one to several days and/or the appearance of blisters or scabs that should not be removed or popped at any time to avoid marks on the skin.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· The interval between sessions should be a minimum of two months, although on occasion it may be advisable to space them out more.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Even though it is uncommon, the laser can produce changes in skin pigmentation in the treated area and although they heal in a few months, they could exceptionally be permanent.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· Scars or marks may appear on the skin derived from a bad tattooing procedure or a bad healing procedure on the part of the client.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· I commit to carrying out the treatment care faithfully following all the instructions of the laser technician.</p>
                                            <p class="mb-0"  *ngIf="trabajo?.idioma_consentimiento == 'es'">· I authorize the session to be filmed or photographed for advertising purposes and/or monitoring of the laser tattoo removal treatment both by us and by our collaborating centers.</p>
                                        </div>
                                    </div>
                
                                    
                                </div>
                                
                                <!-- Consentimiento andaluz -->
                                <div *ngIf="trabajo?.idioma_consentimiento == 'and'">
                                    <div class="containter">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <img class="img-fluid" src="assets/images/cab_cons_and.jpg">
                                            </div>
                                            <div class="col-md-12 my-3">
                                                <h5 class="titulo_cons_and font-weight-bold">FORMULARIO DE INFORMACIÓN Y CONSENTIMIENTO INFORMADO</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-hover table-sm tabla_ci_andalucia mb-2">
                                        <tbody>
                                        <tr class="bg-soft-success">
                                            <td colspan="4" class="font-weight-bolder">1 | DATOS DE IDENTIFICACIÓN</td>
                                        </tr>
                                        <tr class="bg-soft-light">
                                            <td colspan="2" class="font-weight-bolder">
                                                <strong>NOMBRE DEL CENTRO:</strong><br>
                                                {{ trabajo?.estudio.nombre }}
                                            </td>
                                            <td colspan="2" class="font-weight-bolder">
                                                NIF/CIF:<br>
                                                {{ trabajo?.estudio.cif }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">Dirección: {{ trabajo?.estudio.direccion }}</td>
                                        </tr>
                                        <tr>
                                            <td>Población: {{ trabajo?.estudio.localidad.nombre }}</td>
                                            <td>Provincia: {{ trabajo?.estudio.provincia.nombre }}</td>
                                            <td>País: España</td>
                                            <td>Cód. Postal: {{ trabajo?.estudio.cp }}</td>
                                        </tr>
                
                
                                        <tr class="bg-soft-light">
                                            <td colspan="3" class="font-weight-bolder">
                                                NOMBRE Y APELLIDOS DE LA PERSONA APLICADORA DE LA TÉCNICA:<br>
                                                {{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }}
                                            </td>
                                            <td class="font-weight-bolder">
                                                DNI:<br>
                                                {{ trabajo?.artista.dni }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">Domicilio: <span *ngIf="trabajo?.artista.direccion">{{ trabajo?.artista.direccion }}</span></td>
                                        </tr>
                                        <tr>
                                            <td>Población: <span *ngIf="trabajo?.artista.localidad">{{ trabajo?.artista.localidad.nombre }}</span></td>
                                            <td>Provincia: <span *ngIf="trabajo?.artista.provincia">{{ trabajo?.artista.provincia.nombre }}</span></td>
                                            <td>País: <span *ngIf="trabajo?.artista.pais">{{ trabajo?.artista.pais.nombre }}</span></td>
                                            <td>Cód. Postal: <span *ngIf="trabajo?.artista.cp">{{ trabajo?.artista.cp }}</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">Teléfono: {{ trabajo?.artista.telefono }}</td>
                                            <td colspan="2">Correo electrónico: {{ trabajo?.artista.email }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                
                                    <table class="table table-hover table-sm tabla_ci_andalucia mb-2">
                                        <tbody>
                                        <tr class="bg-soft-success">
                                            <td colspan="4" class="font-weight-bolder">2 | DENOMINACIÓN GENÉRICA DE LA TÉCNICA CORRESPONDIENTE QUE SE VA A APLICAR</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                Este documento es suscrito para que la persona usuaria, o quien represente a la misma, preste su consentimiento para ser sometida a la siguiente técnica de decoración corporal: 
                                                <span class="font-weight-bolder" *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">Tatuaje</span>
                                                <span class="font-weight-bolder" *ngIf="trabajo?.tipo_proyecto == 3">Piercing</span>
                                                <span class="font-weight-bolder" *ngIf="trabajo?.tipo_proyecto == 4">Eliminación de tatuaje con láser</span>
                                                <span class="font-weight-bolder" *ngIf="trabajo?.tipo_proyecto == 5">Micropigmentación <span *ngIf="trabajo?.fase_cita == 5">- labios</span><span *ngIf="trabajo?.fase_cita == 6">- cejas</span><span *ngIf="trabajo?.fase_cita == 7">- eyeliner</span><span *ngIf="trabajo?.fase_cita == 8">- microblading</span></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                Localización anatómica: <span *ngFor="let zona of trabajo?.zonas"> {{ zona.nombre }} </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                
                                    <table class="table table-hover table-sm tabla_ci_andalucia mb-2">
                                        <tbody>
                                        <tr class="bg-soft-success">
                                            <td class="font-weight-bolder">3 | DESCRIPCIÓN DETALLADA DE LA TÉCNICA QUE SE VA A APLICAR</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- ESTA TÉCNICA ES PERMANENTE: </span> <span class="text-dark">Si</span> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- ESTA TÉCNICA ES PARA TODA LA VIDA: </span> <span class="text-dark">Si</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- EL PASO DEL TIEMPO PUEDE SUPONER UNA ALTERACIÓN O DECOLORACIÓN DE LA ZONA EN LA QUE SE APLIQUE LA TÉCNICA: </span> <span class="text-dark">Si</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- CÓMO SE REALIZA: </span> <span class="text-dark">INTRODUCIENDO PIGMENTOS (TINTA) MEDIANTE REPETIDOS PINCHAZOS, CON UN CONJUNTO DE AGUJAS ESPECIALMENTE DISEÑADAS PARA ESTE COMETIDO.</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- PUEDE QUE REQUIERA RETOQUES: </span> <span class="text-dark">SI.</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- MATERIALES QUE SE EMPLEAN Y CARACTERÍSTICAS DE LOS MISMOS: </span> <span class="text-dark">DISTINTOS TIPOS DE TINTAS / AGUJAS DE TATUAJE (VARILLA Y CARTUCHO) / MÁQUINAS DE TATUAR (ROTATIVA Y BOBINAS) / SERVILLETAS O ROLLOS DE PAPEL / DISTINTOS TIPOS DE JABONES / AGUA DESTILADA / HOJAS DE CALCO / VASELINA / MANTECA PARA TATUAJES / CUPS / RASURADORAS / CAMPOS / FILM / TIPS / GRIPS / GUANTES (LATEX Y NITRILO).</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- EFECTOS QUE PUEDE TENER LA APLICACIÓN DE LA TÉCNICA: </span> <span class="text-dark">PIGMENTACIÓN DE LA PIEL, IRRITACIÓN, ROJEZ, EROSIÓN DE LA PIEL, SANGRADO, COSTRAS, INFLAMACIÓN, DOLOR.</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- RIESGOS QUE PUEDE TENER LA APLICACIÓN DE LA TÉCNICA: </span> <span class="text-dark">DISTINTOS TIPOS DE INFECCIÓN, ALERGIAS DE DISTINTO TIPO, QUELOIDES, PÉRDIDA DE SENSIBILIDAD EN LA ZONA DE APLICACIÓN.</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- MEDIDAS HIGIÉNICO-SANITARIAS A ADOPTAR EN LA APLICACIÓN DE LA TÉCNICA: </span> <span class="text-dark"> TODO EL MATERIAL EMPLEADO EN LA TÉCNICA ES ESTERILIZADO Y DE UN SOLO USO. LIMPIEZA DE MANOS. DESINFECCIÓN DE TODA LA SUPERFICIE DE TRABAJO Y ELEMENTOS NECESARIOS. CORRECTA ASEPSIA DEPENDIENDO DE LA ZONA. CORRECTO DESECHO DE TODO EL MATERIAL DE UN SOLO USO UTILIZADO. CORRECTA HIGIENE PERSONAL.</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- PRESUPUESTO Y COSTE TOTAL DEL SERVICIO: </span> <span class="text-dark"> {{ trabajo?.precio }}€</span>  
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="font-weight-bolder">- OTRA INFORMACIÓN DE INTERÉS: </span> <span class="text-dark"> AL CLIENTE SE LE INFORMA DE LAS DISTINTAS PAUTAS A SEGUIR PARA UNA CORRECTA HIGIENE Y CICATRIZACIÓN DE LA ZONA SIENDO POR ELLO RESPONSABLE DE SU LIMPIEZA Y SANACIÓN. AL FIRMAR ESTE DOCUMENTO SE EXIME DE RESPONSABILIDAD TANTO AL NEGOCIO COMO AL ARTISTA, PUESTO QUE SE TRATA DE UN TRABAJO ARTÍSTICO REALIZADO A MANO Y PUEDE TENER FALLOS O DETALLES QUE DIFIERAN DEL GUSTO SUBJETIVO DEL CLIENTE. ANTES DE LA REALIZACIÓN DEL MISMO SE LE MOSTRARÁ UN BOCETO AL CLIENTE A FIN DE CORREGIR ASPECTOS DEL MISMO QUE DESEE MODIFICAR.</span>  
                                            </td>
                                        </tr>
                                        <tr *ngIf="trabajo?.anestesia == 1">
                                            <td>
                                                <span class="font-weight-bolder">- EL CLIENTE HA APLICADO UNA CREMA ANESTÉSICA SOBRE LA ZONA TRATADA BAJO SU PROPIA RESPONSABILIDAD.</span>
                                            </td>
                                        </tr>
                                        
                                        </tbody>
                                    </table>
                
                                    <table class="table table-hover table-sm tabla_ci_andalucia mb-2">
                                        <tbody>
                                        <tr class="bg-soft-success">
                                            <td colspan="4" class="font-weight-bolder">PROTECCIÓN DE DATOS</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                En cumplimiento de lo dispuesto por la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, se le informa que los datos personales obtenidos mediante la cumplimentación de este documento, van a ser incorporados, para su tratamiento, al correspondiente fichero de datos de carácter personal. Asimismo, se le informa que la recogida y tratamiento de dichos datos tiene como finalidad la custodia del consentimiento expreso necesario para realizar la técnica solicitada. De acuerdo con lo previsto en la citada Ley Orgánica, puede ejercitar los derechos de acceso, rectificación, cancelación y oposición dirigiendo un escrito al responsable del tratamiento, a la dirección indicada en el apartado 1 de este Anexo.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    
                                    <table class="table table-hover table-sm tabla_ci_andalucia mb-2">
                                        <tbody>
                                        <tr class="bg-soft-success">
                                            <td class="font-weight-bolder">AUTORIZACIÓN PARA LA PUBLICACIÓN DE IMÁGENES</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Con la inclusión de las nuevas tecnologías dentro de las comunicaciones, publicaciones y acciones comerciales que puede realizar <strong>{{ trabajo?.estudio.nombre }}</strong> y la posibilidad de que en estas puedan aparecer los datos personales y/o imágenes que ha proporcionado a nuestra empresa dentro del vínculo comercial existente. Y dado que el derecho a la propia imagen está reconocido al artículo 18 de la Constitución y regulado por la Ley 1/1982, de 5 de mayo, sobre el derecho al honor, a la intimidad personal y familiar y a la propia imagen y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{ trabajo?.estudio.nombre }}</strong> pide su consentimiento para poder publicar los datos personales que nos ha facilitado o imágenes en las cuales aparezcan individualmente o en grupo que con carácter comercial se puedan realizar con nuestra empresa.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- Consentimiento castellano reducido -->
                                <div *ngIf="trabajo?.idioma_consentimiento == 'es_redu'" class="row">
                                    <div class="col-md-3">
                                        <div class="card bg-soft-light">
                                            <div class="card-body">
                                                <h4 class="card-title">Establecimiento</h4>
                                                <p class="mb-1 text-dark"><strong>{{ trabajo?.estudio.nombre }}</strong></p>
                                                <p class="mb-0 small">{{ trabajo?.estudio.direccion }}. {{ trabajo?.estudio.cp }}. {{ trabajo?.estudio.localidad.nombre }}</p>
                                                <p class="mb-0 small">Teléfono: {{ trabajo?.estudio.telefono }}</p>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title">Técnico/aplicador</h4>
                                                <p class="mb-1 text-dark"><strong>{{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }}</strong></p>
                                                <p class="mb-0 small">DNI: {{ trabajo?.artista.dni }}</p>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div class="col-md-9">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title">TÉCNICA A REALIZAR</h4>
                                                <p class="mb-1">
                                                    <strong>Denominación genérica: </strong>
                                                    <span *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">Tatuaje</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 3">Piercing</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 4">Eliminación de tatuaje con láser</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 5">Micropigmentación <span *ngIf="trabajo?.fase_cita == 5">- labios</span><span *ngIf="trabajo?.fase_cita == 6">- cejas</span><span *ngIf="trabajo?.fase_cita == 7">- eyeliner</span><span *ngIf="trabajo?.fase_cita == 8">- microblading</span></span>
                                                </p>
                                                <p class="mb-1"><strong>Localización anatómica:</strong>  <span *ngFor="let zona of trabajo?.zonas"> {{ zona.nombre }} </span></p>
                
                                                <hr>
                
                
                                                <h4 class="card-title">CARACTERÍSTICAS DE LOS MATERIALES</h4>
                                                <p class="mb-1"><strong>Todos los pigmentos utilizados están autorizados por la Agencia Española de Medicamentos y Productos Sanitarios.</strong></p>
                                                
                                                <hr>
                
                
                                                <h4 class="card-title">ENTIENDO Y DECLARO</h4>
                                                <p class="mb-1" *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en la perforación de la piel y/o mucosa del cuerpo para facilitar la introducción de pigmentos y que esto se realiza con agujas.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 3"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en perforar la piel, las mucosas u otros tejidos con el fin de colocar joyas u otros objetos de metal.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 4"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en destruir de forma controlada el pigmento del tatuaje mediante el uso de una luz láser.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 5"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio es un procedimiento estético que tiene como finalidad el embellecimiento de distintas partes del cuerpo y/o el camuflaje de cicatrices, quemaduras y otras alteraciones de la capa más visible de la piel.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que el trabajo es ejecutado por un técnico.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Declaro que no estoy recibiendo ningún tratamiento médico que pueda afectar al procedimiento, que no estoy bajo la influencia de ningún tipo de drogas, medicamento o alcohol y que no tengo ningún tipo de alergia a producto alguno de la sea conocedor/a.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> He sido informado del procedimiento de cuidados y recomendaciones posteriores.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Soy conocedor de que el material que se utilizará es totalmente desechable y esterilizado.</p>
                                                <p class="mb-1" *ngIf="trabajo?.anestesia == 1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> He aplicado una crema anestésica en la zona tratada bajo mi propia responsabilidad.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> <a href="#" class="text-dark" data-toggle="modal" data-target="#listado_enfermedades">Mostrar listado de enfermedades, alergias o situaciones que pueden afectar al procedimiento.</a></p>
                                            
                                                <hr>
                
                                                <div *ngIf="trabajo?.comentarios_consentimiento">
                                                    <h4 class="card-title">COMENTARIOS CONCRETOS</h4>
                                                    <p class="mb-1"><strong>{{trabajo?.comentarios_consentimiento}}</strong></p>
                                                    <hr>
                                                </div>
                
                
                                                <h4 class="card-title">CONFIRMO</h4>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que el servicio a realizar me ha sido explicado a fondo, en palabras comprensibles y que soy conocedor de los riesgos que pudiera tener y del procedimiento de cuidados a seguir en los días posteriores. También confirmo que me ha sido entregada una copia informativa de los cuidados de la técnica realizada para obtener el mejor resultado.</p>
                                                <div *ngIf="trabajo?.cliente.cons_redes == 1">
                                                    <hr>
                                                    <h4 class="card-title">AUTORIZACIÓN PARA LA PUBLICACIÓN DE IMÁGENES</h4>
                                                    <p class="mb-1">
                                                        Con la inclusión de las nuevas tecnologías dentro de las comunicaciones, publicaciones y acciones comerciales que puede realizar <strong>{{ trabajo?.estudio.nombre }}</strong> y la posibilidad de que en estas puedan aparecer los datos personales y/o imágenes que ha proporcionado a nuestra empresa dentro del vínculo comercial existente. Y dado que el derecho a la propia imagen está reconocido al artículo 18 de la Constitución y regulado por la Ley 1/1982, de 5 de mayo, sobre el derecho al honor, a la intimidad personal y familiar y a la propia imagen y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos.
                                                    </p>
                                                </div>
                                                <!-- Revocación del consentimiento -->
                                                <div>
                                                    <hr>
                                                    <h4 class="card-title">REVOCACIÓN DE ESTE CONSENTIMIENTO INFORMADO</h4>
                                                    <p class="mb-1">
                                                        Es obligatorio suscribir de manera previa a la prestación del servicio un consentimiento el cual podrá ser revocado en cualquier momento, sin necesidad de expresar motivación alguna.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Consentimiento LATAM -->
                                <div *ngIf="trabajo?.idioma_consentimiento == 'latam'" class="row">
                                    <div class="col-md-3">
                                        <div class="card bg-soft-light">
                                            <div class="card-body">
                                                <h4 class="card-title">Establecimiento</h4>
                                                <p class="mb-1 text-dark"><strong>{{ trabajo?.estudio.nombre }}</strong></p>
                                                <p class="mb-0 small">{{ trabajo?.estudio.direccion }}. {{ trabajo?.estudio.cp }}. {{ trabajo?.estudio.localidad.nombre }}</p>
                                                <p class="mb-0 small">Teléfono: {{ trabajo?.estudio.telefono }}</p>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title">Técnico/aplicador</h4>
                                                <p class="mb-1 text-dark"><strong>{{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }}</strong></p>
                                                <p class="mb-0 small">DNI: {{ trabajo?.artista.dni }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title">TÉCNICA A REALIZAR</h4>
                                                <p class="mb-1">
                                                    <strong>Denominación genérica: </strong>
                                                    <span *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2">Tatuaje</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 3">Piercing</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 4">Eliminación de tatuaje con láser</span>
                                                    <span *ngIf="trabajo?.tipo_proyecto == 5">Micropigmentación <span *ngIf="trabajo?.fase_cita == 5">- labios</span><span *ngIf="trabajo?.fase_cita == 6">- cejas</span><span *ngIf="trabajo?.fase_cita == 7">- eyeliner</span><span *ngIf="trabajo?.fase_cita == 8">- microblading</span></span>
                                                </p>
                                                <p class="mb-1"><strong>Localización anatómica:</strong>  <span *ngFor="let zona of trabajo?.zonas"> {{ zona.nombre }} </span></p>
                
                                                <hr>
                
                
                                                <h4 class="card-title">CARACTERÍSTICAS DE LOS MATERIALES</h4>
                                                <p class="mb-1"><strong>Todos los pigmentos utilizados cuentan con homologación y cumplen con la normativa.</strong></p>
                
                                                <hr>
                
                
                                                <h4 class="card-title">ENTIENDO Y DECLARO</h4>
                                                <p class="mb-1" *ngIf="trabajo?.fase_cita == 3 || trabajo?.fase_cita == 4 || trabajo?.tipo_proyecto == 2"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en la perforación de la piel y/o mucosa del cuerpo para facilitar la introducción de pigmentos y que esto se realiza con agujas para dermopigmentación nuevas, esterilizadas y desechables.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 3"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en perforar la piel, las mucosas u otros tejidos con el fin de colocar joyas u otros objetos de metal.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 4"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio consiste en destruir de forma controlada el pigmento del tatuaje mediante el uso de una luz láser.</p>
                                                <p class="mb-1" *ngIf="trabajo?.tipo_proyecto == 5"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que este servicio es un procedimiento estético que tiene como finalidad el embellecimiento de distintas partes del cuerpo y/o el camuflaje de cicatrices, quemaduras y otras alteraciones de la capa más visible de la piel.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que el trabajo es ejecutado por un técnico.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Declaro que no estoy recibiendo ningún tratamiento médico que pueda afectar al procedimiento, que no estoy bajo la influencia de ningún tipo de drogas, medicamento o alcohol y que no tengo ningún tipo de alergia a producto alguno de la sea conocedor/a.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> He sido informado del procedimiento de cuidados y recomendaciones posteriores.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Soy conocedor de que el material que se utilizará es totalmente desechable y esterilizado.</p>
                                                <p class="mb-1" *ngIf="trabajo?.anestesia == 1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> He aplicado una crema anestésica en la zona tratada bajo mi propia responsabilidad.</p>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> <a href="#" class="text-dark" data-toggle="modal" data-target="#listado_enfermedades">Mostrar listado de enfermedades, alergias o situaciones que pueden afectar al procedimiento.</a></p>
                                                <!-- modal con lista de situacions -->
                                                <div id="listado_enfermedades" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="listado_enfermedades_Label" aria-hidden="true">
                                                    <div class="modal-dialog modal-lg">
                                                        <div class="modal-content tarjeta_dark border-0">
                                                            <div class="modal-header border-dark">
                                                                <h5 class="modal-title text-white mt-0" id="listado_enfermedades_Label">Listado de situaciones</h5>
                                                                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="table-responsive">
                                                                    <table class="table table-sm m-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> Hipertensión</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Diabetes</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Qué tipo?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Cáncer</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Enfermedades de la tiroides</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Enf. de la glándula suprarrenal</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Enf. del corazón, pulmón, higado y/o riñón</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Enfermedades autoinmunes</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> Enfermedades psiquiátricas</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Alguna enfermedad que no se encuentre entre las anteriores?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Toma alguna medicación?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Ha tenido alguna intervención quirúrgica?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Toma algún tipo de anticonceptivo o tratamiento hormonal?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Padece algún tipo de trastorno hormonal?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Padece o ha padecido alguna afección de la piel?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿En qué zona?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> ¿Podría estar embarazada?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> ¿Padece o ha padecido episodios de epilepsia?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> ¿Tiene algún implante metálico?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> Alergias a alimentos, medicamentos, cosméticos...</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Ha tenido cáncer o alguna lesión pre cancerosa?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Hace cuánto?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Su piel se inflama o irrita con facilidad?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Problemas de cicatrización?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary" colspan="2"><i class="bx bx-caret-right mr-1"></i> ¿Problemas de coagulación?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bx-caret-right mr-1"></i> ¿Padece o ha padecido algún tipo de Herpes?</td>
                                                                                <td class="text-white border-bottom border-top-0 border-secondary"><i class="bx bxs-help-circle mr-1"></i> ¿Cuál?</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-white border-0"><i class="bx bx-caret-right mr-1"></i> ¿Toma el sol con frecuencia o rayos UVA?</td>
                                                                                <td class="text-white border-0"><i class="bx bxs-help-circle mr-1"></i> ¿Qué SPF utiliza?</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer border-0 d-block pt-0">
                                                                <button type="button" class="btn btn-sm btn-light waves-effect" data-dismiss="modal">Cerrar</button>
                                                            </div>
                                                        </div><!-- /.modal-content -->
                                                    </div><!-- /.modal-dialog -->
                                                </div><!-- /.modal -->
                                                <hr>
                                                <div *ngIf="trabajo?.comentarios_consentimiento">
                                                    <h4 class="card-title">COMENTARIOS CONCRETOS</h4>
                                                    <p class="mb-1"><strong>{{trabajo?.comentarios_consentimiento}}</strong></p>
                                                    <hr>
                                                </div>
                                                <h4 class="card-title">CONFIRMO</h4>
                                                <p class="mb-1"><i class="mdi mdi-circle-medium align-middle text-primary me-1"></i> Que el servicio a realizar me ha sido explicado a fondo, en palabras comprensibles y que soy conocedor de los riesgos que pudiera tener y del procedimiento de cuidados a seguir en los días posteriores. También confirmo que me ha sido entregada una copia informativa de los cuidados de la técnica realizada para obtener el mejor resultado.</p>
                                                <div *ngIf="trabajo?.cliente.cons_redes == 1">
                                                    <hr>
                                                    <h4 class="card-title">AUTORIZACIÓN PARA LA PUBLICACIÓN DE IMÁGENES</h4>
                                                    <p class="mb-1">
                                                        Con la inclusión de las nuevas tecnologías dentro de las comunicaciones, publicaciones y acciones comerciales que puede realizar <strong>{{ trabajo?.estudio.nombre }}</strong> y la posibilidad de que en estas puedan aparecer los datos personales y/o imágenes que ha proporcionado a nuestra empresa dentro del vínculo comercial existente. Y dado que el derecho a la propia imagen está reconocido y regulado por la Ley, sobre el derecho al honor, a la intimidad personal y familiar y a la propia imagen y relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos.
                                                    </p>
                                                </div>
                
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <!-- Firma global del documento -->
                                <div class="row">
                                    <div class="col-md-12 d-inline d-md-none">
                                        <div class="alert alert-info fade show mt-0 mb-3" role="alert">
                                            <i class="bx bx-rotate-left font-size-18 align-middle"></i> Girando el móvil tendrás más espacio para firmar.
                                        </div>
                                    </div>    
                                </div>
                                <div class="col-md-12 border border-dark mb-3 p-3">
                                    <h5 class="font-size-15 font-weight-bold" *ngIf="trabajo?.estudio.localidad_id">En {{ trabajo?.estudio.localidad.nombre }}, a {{ today }}</h5>
                                    <div class="row d-flex justify-content-between">
                                        <div class="col mr-3">
                                            <div class="bloque_firma" *ngIf="!trabajo?.firma_consentimiento_cliente">
                                                <signature-pad #signaturePad [options]="signaturePadOptions" (onEndEvent)="firmaCompleta()" (onBeginEvent)="inicioFirma()"></signature-pad>
                                                <div class="text-left mt-2 mb-3 mb-md-0 ng-star-inserted" *ngIf="hayFirma">
                                                    <button type="button" class="btn btn-outline-danger btn-sm btn-block btn-md-inline" (click)="limpiarFirma()">
                                                        <i class="bx bx-eraser mr-1"></i> Repetir firma
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="bloque_firmado" *ngIf="trabajo?.firma_consentimiento_cliente">
                                                <img src="{{ global_url +  trabajo.firma_consentimiento_cliente }}" alt="">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <p class="text-muted mb-0">
                                                <u *ngIf="trabajo?.idioma_consentimiento == 'es'"><strong>Firma del aplicador:</strong> {{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }} - {{ trabajo?.artista.dni }}</u>
                                                <u *ngIf="trabajo?.idioma_consentimiento == 'ca'"><strong>Signatura de l'aplicador:</strong> {{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }} - {{ trabajo?.artista.dni }}</u>
                                                <u *ngIf="trabajo?.idioma_consentimiento == 'en'"><strong>Signature of the applicator:</strong> {{ trabajo?.artista.nombre }} {{ trabajo?.artista.apellido1 }} {{ trabajo?.artista.apellido2 }} - {{ trabajo?.artista.dni }}</u>
                                            </p>
                                
                                            <div class="bloque_firmado">
                                                <img src="{{ global_url +  trabajo?.artista.firma }}" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">
                                    <p class="mb-0 text-danger font-size-10"><i><u>ADVERTENCIA CONSENTIMIENTO TELEMÁTICO</u> La firma de este consentimiento informado se va a realizar telemáticamente por el cliente, lo que NO implica que se haya realizado el procedimiento de tatuaje, piercing, láser, o cualquier otro servicio detallado en este documento. Este consentimiento informado sólo certifica la comprensión y aceptación de los riesgos y responsabilidades relacionadas con el procedimiento que está programado para realizarse en nuestro estudio. En caso de no presentarse a la cita programada, este consentimiento informado queda automáticamente invalidado y no puede ser utilizado como prueba de la realización del procedimiento en nuestro estudio. Cualquier mal uso de este documento será perseguido legalmente.</i></p> 
                                </div>
                                <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">
                                    <p class="mb-0 text-danger font-size-10"><i><u>AVÍS CONSENTIMENT TELEMÀTIC</u> La signatura d'aquest consentiment informat es realitzarà telemàticament pel client, la qual NO implica que s'hagi realitzat el procediment de tatuatge, pírcing, làser, o qualsevol altre servei detallat en aquest document. Aquest consentiment informat només certifica la comprensió i acceptació dels riscos i responsabilitats relacionades amb el procediment que està programat per a realitzar-se al nostre estudi. En cas de no presentar-se a la cita programada, aquest consentiment informat queda automàticament invalidat i no pot ser utilitzat com a prova de la realització del procediment al nostre estudi. Qualsevol mal ús d'aquest document serà perseguit legalment.</i></p>
                                 </div>
                                 <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">
                                    <p class="mb-0 text-danger font-size-10"><i><u>TELEMATIC CONSENT WARNING</u> The signature of this informed consent is going to be made telematically by the client, which DOES NOT imply that the tattoo, piercing, laser procedure, or any other service detailed in this document has been carried out. This informed consent only certifies the understanding and acceptance of the risks and responsibilities related to the procedure that is scheduled to be performed in our studio. In case of not showing up for the scheduled appointment, this informed consent is automatically invalidated and cannot be used as proof of the procedure having been carried out in our studio. Any misuse of this document will be legally pursued.</i></p>
                                 </div>

                                <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'ca'">
                                    <p class="mb-0 text-muted font-size-10"><i><span *ngIf="trabajo?.cliente.cliente_estudio">El client ha assistit al estudi per recomanació de l'artista, sense intervenció per part de l'estudi en la seva captació com a client.</span> Es dóna còpia d'aquest document juntament amb els annexos d'informació a la persona usuària. Totes les dades d'aquest full es conservaràn sota la responsabilitat de la titularitat de l'establiment durant tres anys des de la seva formalització, a disposició de les autoritats competent i es guardaràn de conformitat amb el Reglament (UE) 2016/679 del Parlament i del Consell, de 27 d'abril de 2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d'aquestes dades.</i></p> 
                                </div>
                                <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'es'">
                                    <p class="mb-0 text-muted font-size-10"><i><span *ngIf="trabajo?.cliente.cliente_estudio">El cliente ha asistido al estudio por recomendación del artista, sin intervención por parte del estudio en su captación como cliente.</span> Se entrega copia de este documento junto con los anexos de información al usuario. Todos los datos de esta hoja se conservarán bajo la responsabilidad de la titularidad del establecimiento durante tres años desde su formalización, a disposición de las autoridad competente y se guardarán de conformidad con el Reglamento (UE) 2016/679 del Parlamento y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que se refiere al tratamiento de datos personales ya la libre circulación de estos datos.</i></p> 
                                </div>
                                <div class="col-md-12 mb-3 px-0" *ngIf="trabajo?.idioma_consentimiento == 'en'">
                                    <p class="mb-0 text-muted font-size-10"><i><span *ngIf="trabajo?.cliente.cliente_estudio">The client has attended the studio by recommendation of the artist, without intervention by the studio in its recruitment as a client.</span> A copy of this document along with the information annexes will be provided to the user. All the data in this form will be kept under the responsibility of the establishment for three years from its formalization, available to the competent authorities and will be stored in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data.</i></p> 
                                </div>
                                <div class="d-print-none mt-4">
                                    <div class="float-right">
                                        <button class="btn btn-primary waves-effect waves-light mr-1" *ngIf="!trabajo?.consentimiento" (click)="sign(formSign.value)" [disabled]="formSign.invalid"><i class="fa fa-save"></i> Guardar y generar PDF</button>
                                    </div>
                                </div>      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>