import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApicallsService } from '../../services/apicalls.service';
import { AuthenticationService } from '../../services/authentication.service';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-trabajo-acciones',
  templateUrl: './modal-trabajo-acciones.component.html',
  styleUrls: ['./modal-trabajo-acciones.component.scss']
})
export class ModalTrabajoAccionesComponent {
  @Input() trabajo: any;
  @Input() estudio_id: string;

  user: any;
  permisosArtista: any;

  // Estados posibles:
  // 'opciones' (menú inicial),
  // 'editar' (mostrar formulario de edición),
  // 'confirmar-cancelar', 'confirmar-finalizar' y 'confirmar-eliminar' (confirmaciones)
  estado: string = 'opciones';

  // Objeto para almacenar los datos editables (prellenado con la info del trabajo)
  datosTrabajo: any = {};

  // Lista de artistas y cabinas
  artistas: any[] = [];
  cabinas: any[] = [];
  ubicaciones: any[] = [];
  // Determinar si el estudio tiene cabinas
  tieneCabinas: boolean = false;
  
  // Determinar si el estudio tiene ubicaciones
  tieneUbicaciones: boolean = false;

  // Determinar si se muestran los campos de la señal
  mostrarCamposSenal: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private api: ApicallsService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    console.log("Objeto trabajo recibido en el modal:", this.trabajo);
    console.log("Objeto estudio_id recibido en el modal:", this.estudio_id);
    console.log("ExtendedProps del trabajo:", this.trabajo.extendedProps);

    this.user = this.auth.currentUserValue;
    if (this.user.rol_id === 4) {
      // Cargar permisos del artista
      this.api.getPermisosArtista(this.user.id).subscribe(
        (response: any) => {
          if (response.success) {
            this.permisosArtista = response.data;
            console.log("Permisos del artista:", this.permisosArtista);
          }
        },
        (error) => {
          console.error('Error al obtener permisos del artista:', error);
        }
      );
    }

    // Inicializar campos para la cancelación
    this.datosTrabajo.noSePresento = false;
    this.datosTrabajo.motivosCancelacion = '';
    
    // Extraer el ID del estudio del evento (si está disponible)
    // Nota: esto es solo un ejemplo, puede que necesites obtener el estudio_id de otra manera
    this.estudio_id = this.estudio_id;
    
    // Cargar la lista de artistas del estudio
    this.cargarArtistas();

    // Cargar las cabinas del estudio
    this.cargarCabinas();

    // Cargar las ubicaciones del estudio
    this.cargarUbicaciones();
  }

  // Método para cargar los artistas del estudio
  cargarArtistas() {
    this.api.getArtistasNew(this.estudio_id).subscribe((data: any) => {
      this.artistas = data.artistas;
      // Una vez cargados los artistas, si estamos en modo edición,
      // aseguramos que el artista actual esté seleccionado
      if (this.estado === 'editar') {
        // No necesitamos hacer nada más porque la propiedad ya estará establecida
        // en mostrarEditar()
      }
    });
  }

  // Método para cargar las cabinas del estudio
  // Método simplificado para cargar las cabinas del estudio
  cargarCabinas() {
    this.api.getCabinasEstudio(this.estudio_id).subscribe(
      (data: any) => {
        // La respuesta viene en formato data.response
        if (data && data.response && Array.isArray(data.response)) {
          this.cabinas = data.response;
          this.tieneCabinas = this.cabinas.length > 0;
        } else {
          this.tieneCabinas = false;
        }
        
        // Si el trabajo tiene una cabina asignada pero no está en la lista,
        // la añadimos para poder mostrarla y editarla
        if (this.trabajo && this.trabajo.extendedProps?.cabinaNombre && this.trabajo.resourceIds?.length > 0) {
          const cabinaId = this.trabajo.resourceIds[0];
          const existeCabina = this.cabinas.some(c => c.id == cabinaId);
          
          if (!existeCabina) {
            this.cabinas.push({
              id: cabinaId,
              nombre: this.trabajo.extendedProps.cabinaNombre,
              estudio_id: this.estudio_id
            });
            
            this.tieneCabinas = true;
          }
        }
      },
      (error) => {
        this.tieneCabinas = false;
      }
    );
  }

  // Método para cargar las ubicaciones del estudio
  // Método para cargar las ubicaciones del estudio
  cargarUbicaciones() {
    this.api.getUbicacionesEstudio(this.estudio_id).subscribe(
      (data: any) => {
        // La respuesta viene en formato data.response
        if (data && data.response && Array.isArray(data.response)) {
          this.ubicaciones = data.response;
          this.tieneUbicaciones = this.ubicaciones.length > 0;
        } else {
          this.tieneUbicaciones = false;
        }
        
        // Si el trabajo tiene una ubicación asignada pero no está en la lista,
        // la añadimos para poder mostrarla y editarla
        if (this.trabajo && this.trabajo.extendedProps?.ubicacion_id) {
          const ubicacionId = this.trabajo.extendedProps.ubicacion_id;
          const existeUbicacion = this.ubicaciones.some(u => u.id == ubicacionId);
          
          // Si la ubicación no existe en la lista pero tenemos su ID, la añadimos
          if (!existeUbicacion) {
            const nombreUbicacion = this.trabajo.extendedProps.ubicacionNombre || 
                                  'Ubicación ' + ubicacionId;
            
            this.ubicaciones.push({
              id: ubicacionId,
              nombre: nombreUbicacion,
              estudio_id: this.estudio_id
            });
            
            this.tieneUbicaciones = true;
          }
        }
      },
      (error) => {
        this.tieneUbicaciones = false;
      }
    );
  }

  // Cambia al estado de edición
  mostrarEditar() {
    const startMoment = moment(this.trabajo.start);
    const endMoment = moment(this.trabajo.end);

    // Extraer artista_id a partir de classNames (por ejemplo, "artista-3989")
    let artistaId;
    if (this.trabajo.classNames && this.trabajo.classNames[0]) {
      artistaId = this.trabajo.classNames[0].split('-')[1];
    }

    // Extraer cliente_id de extendedProps
    let clienteId = this.trabajo.extendedProps?.cliente_id;

    // Extraer cabina_id de resourceIds o usar ubicacion_id si está disponible
    let cabinaId;
    // Primero intentamos con resourceIds
    if (this.trabajo.resourceIds && this.trabajo.resourceIds.length > 0) {
      cabinaId = this.trabajo.resourceIds[0];
      console.log("Cabina ID extraído de resourceIds:", cabinaId);
    } 
    // Si hay una cabina con nombre, intentamos encontrarla en la lista por nombre
    else if (this.trabajo.extendedProps && this.trabajo.extendedProps.cabinaNombre && this.cabinas.length > 0) {
      const cabina = this.cabinas.find(c => c.nombre === this.trabajo.extendedProps.cabinaNombre);
      if (cabina) {
        cabinaId = cabina.id;
        console.log("Cabina ID encontrado por nombre:", cabinaId);
      } else {
        console.log("No se encontró la cabina por nombre:", this.trabajo.extendedProps.cabinaNombre);
      }
    }

    // Extraer ubicacion_id de resourceIds o usar ubicacion_id si está disponible
    let ubicacionId;
    if (this.trabajo.resourceIds && this.trabajo.resourceIds.length > 0) {
      ubicacionId = this.trabajo.resourceIds[0];
      console.log("Ubicación ID extraído de resourceIds:", ubicacionId);
    }
    // Si hay una ubicación con nombre, intentamos encontrarla en la lista por nombre
    else if (this.trabajo.extendedProps && this.trabajo.extendedProps.ubicacionNombre && this.ubicaciones.length > 0) {
      const ubicacion = this.ubicaciones.find(u => u.nombre === this.trabajo.extendedProps.ubicacionNombre);
      if (ubicacion) {
        ubicacionId = ubicacion.id;
        console.log("Ubicación ID encontrado por nombre:", ubicacionId);  
      } else {
        console.log("No se encontró la ubicación por nombre:", this.trabajo.extendedProps.ubicacionNombre);
      }
    }
    
    this.datosTrabajo = {
      // Extraer artista_id a partir de classNames (por ejemplo, "artista-3989")
      estudio_id: this.estudio_id,
      cliente_id: clienteId,
      artista_id: artistaId,
      comision_artista: this.trabajo.extendedProps.comision_artista,
      cabina_id: cabinaId,
      ubicacion_id: ubicacionId, 
      fecha: startMoment.format('YYYY-MM-DD'),
      horai: startMoment.format('HH:mm'),
      horaf: endMoment.format('HH:mm'),
      precio: this.trabajo.extendedProps.precio,
      descripcion: this.trabajo.extendedProps.descripcion,
      senal: this.trabajo.extendedProps.senal,
      formapago: this.trabajo.extendedProps.formapago || '',
      forma_pagosenal: this.trabajo.extendedProps.forma_pagosenal || '',
      fecha_pagosenal: this.trabajo.extendedProps.fecha_pagosenal,
      recoge_pagosenal: this.trabajo.extendedProps.recoge_pagosenal,
      finalizada: this.trabajo.extendedProps.finalizada
    };
    if (this.datosTrabajo.senal) {
      this.mostrarCamposSenal = true;
    }

    // Validar que forma_pagosenal sea uno de los valores permitidos
    const formasPagoValidas = ['efectivo', 'tarjeta', 'transferencia', 'bizum', 'paypal', 'yappy'];
    if (this.datosTrabajo.forma_pagosenal && !formasPagoValidas.includes(this.datosTrabajo.forma_pagosenal)) {
        this.datosTrabajo.forma_pagosenal = ''; // Si no es válido, lo dejamos vacío
    }
    
    console.log("Datos a editar:", this.datosTrabajo);
    this.estado = 'editar';
  }
  

  // Para confirmar cancelar, finalizar o eliminar, cambia el estado
  confirmarCancelar() {
    this.estado = 'confirmar-cancelar';
  }
  confirmarReactivar() {
    this.estado = 'confirmar-reactivar';
  }
  confirmarFinalizar() {
    this.estado = 'confirmar-finalizar';
  }
  confirmarEliminar() {
    this.estado = 'confirmar-eliminar';
  }

  // Este método se llama desde el menú inicial al seleccionar una acción
  seleccionarAccion(accion: string) {
    if (accion === 'editar') {
      this.mostrarEditar();
    } else if (accion === 'cancelar') {
      this.confirmarCancelar();
    } else if (accion === 'reactivar') {
      this.confirmarReactivar();
    } else if (accion === 'finalizar') {
      this.mostrarEditar(); // Precarga los datos
      this.estado = 'confirmar-finalizar';
    } else if (accion === 'eliminar') {
      this.confirmarEliminar();
    }
  }

  // Responde a la confirmación: si se confirma, cierra el modal enviando la acción;
  // si no, vuelve al menú de opciones.
  respuestaConfirmacion(confirmado: boolean) {
    if (confirmado) {
      if (this.estado === 'confirmar-cancelar') {
        this.activeModal.close({ accionSeleccionada: 'cancelar', datosActualizados: this.datosTrabajo, trabajo: this.trabajo });
      } else if (this.estado === 'confirmar-reactivar') {
        this.activeModal.close({ accionSeleccionada: 'reactivar', trabajo: this.trabajo });
      } else if (this.estado === 'confirmar-finalizar') {
        this.activeModal.close({ accionSeleccionada: 'finalizar', datosActualizados: this.datosTrabajo, trabajo: this.trabajo });
      } else if (this.estado === 'confirmar-eliminar') {
        this.activeModal.close({ accionSeleccionada: 'eliminar', trabajo: this.trabajo });
      }
    } else {
      this.estado = 'opciones';
    }
  }

  // Guardado de cambios de la edición
  guardarEdicion() {
    const id = this.trabajo.id.split('-')[1];

    // Validar campos críticos antes de enviar
    if (!this.datosTrabajo.artista_id) {
        delete this.datosTrabajo.artista_id;
    }
    if (!this.datosTrabajo.cliente_id) {
        this.datosTrabajo.cliente_id = this.trabajo.extendedProps?.cliente_id;
    }
    if (!this.datosTrabajo.estudio_id) {
        this.datosTrabajo.estudio_id = this.estudio_id;
    }

    this.api.editTrabajoFromCalendar(this.datosTrabajo, id).subscribe(
        (response: any) => {
            // Cerrar el modal con un indicador de éxito y los datos actualizados
            this.activeModal.close({ 
                accionSeleccionada: 'editar',
                datosActualizados: this.datosTrabajo,
                trabajo: this.trabajo,
                success: true // Añadimos este flag para indicar éxito
            });
        },
        (error) => {
            console.error('Error al actualizar el trabajo', error);
            this.activeModal.dismiss('Error al actualizar el trabajo');
        }
    );
  }

  // Guardado de finalización
  guardarFinalizacion() {
    const id = this.trabajo.id.split('-')[1];
    this.api.finalizarCitaFromCalendar(this.datosTrabajo, id).subscribe(
      (response: any) => {
        // Cierra el modal indicando éxito en la acción "finalizar"
        this.activeModal.close({
          success: true,
          accionSeleccionada: 'finalizar',
          datosActualizados: this.datosTrabajo,
          trabajo: this.trabajo
        });
      },
      (error) => {
        console.error('Error al finalizar la cita', error);
        this.activeModal.dismiss('Error al finalizar la cita');
      }
    );
  }

  // Guardado de cancelación
  guardarCancelacion() {
    const id = this.trabajo.id.split('-')[1];
    this.api.cancelarCitaFromCalendar(this.datosTrabajo, id).subscribe(
      (response: any) => {
        // Cierra el modal indicando éxito en la acción "cancelar"
        this.activeModal.close({
          success: true,
          accionSeleccionada: 'cancelar',
          datosActualizados: this.datosTrabajo,
          trabajo: this.trabajo
        });
      },
      (error) => {
        console.error('Error al cancelar la cita', error);
        this.activeModal.dismiss('Error al cancelar la cita');
      }
    );
  }
  
  // Guardado de reactivación
  guardarReactivacion() {
    const id = this.trabajo.id.split('-')[1];
    this.api.reactivarCitaFromCalendar(this.datosTrabajo, id).subscribe(
      (response: any) => {
        // Cierra el modal indicando éxito en la acción "cancelar"
        this.activeModal.close({
          success: true,
          accionSeleccionada: 'reactivar',
          datosActualizados: this.datosTrabajo,
          trabajo: this.trabajo
        });
      },
      (error) => {
        console.error('Error al reactivar la cita', error);
        this.activeModal.dismiss('Error al reactivar la cita');
      }
    );
  }
  

  // Cancela la edición y vuelve al menú inicial
  cancelarEdicion() {
    this.estado = 'opciones';
  }
}
