<div class="modal-header">
  <!-- Títulos según el estado -->
  <h4 class="modal-title text-light" *ngIf="estado === 'opciones'">
    <i class="bx bx-menu-alt-left mr-2"></i>Acciones de la cita
  </h4>
  <h4 class="modal-title text-light" *ngIf="estado === 'editar'">
    <i class="bx bx-edit mr-2"></i>Editar cita
  </h4>
  <h4 class="modal-title text-light" *ngIf="estado === 'confirmar-cancelar'">
    <i class="bx bx-x-circle mr-2"></i>Confirmar cancelación
  </h4>
  <h4 class="modal-title text-light" *ngIf="estado === 'confirmar-reactivar'">
    <i class="bx bx-refresh mr-2"></i>Confirmar reactivación
  </h4>
  <h4 class="modal-title text-light" *ngIf="estado === 'confirmar-finalizar'">
    <i class="bx bx-check-circle mr-2"></i>Confirmar finalización
  </h4>
  <h4 class="modal-title text-light" *ngIf="estado === 'confirmar-eliminar'">
    <i class="bx bx-trash mr-2"></i>Confirmar eliminación
  </h4>
  <button type="button" class="close text-white" aria-label="Cerrar" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <!-- Información del trabajo visible en todos los estados -->
  <div class="info-trabajo" *ngIf="trabajo">
    <div class="info-grid">
      <div class="info-item">
        <span class="info-label"><i class="bx bx-user"></i> Cliente/Artista</span>
        <span class="info-content">{{trabajo.title}}</span>
      </div>
      
      <div class="info-item">
        <span class="info-label"><i class="bx bx-calendar"></i> Fecha</span>
        <span class="info-content">{{trabajo.start | date:'dd/MM/yyyy'}}</span>
      </div>
      
      <div class="info-item">
        <span class="info-label"><i class="bx bx-time"></i> Hora</span>
        <span class="info-content">{{trabajo.start | date:'HH:mm'}} - {{trabajo.end | date:'HH:mm'}}</span>
      </div>
      
      <div class="info-item" *ngIf="trabajo.extendedProps?.precio && !(user?.rol_id === 4 && permisosArtista?.ver_totales === 0)">
        <span class="info-label"><i class="bx bx-euro"></i> Precio</span>
        <span class="info-content">{{trabajo.extendedProps.precio}}€ <span class="small" *ngIf="trabajo.extendedProps?.senal">({{trabajo.extendedProps.senal}}€ <i class="bx bx-right-arrow-alt"></i> {{trabajo.extendedProps.recoge_pagosenal === 1 ? 'EST' : 'ART'}})</span></span>
      </div>

    </div>
  </div>

  <!-- Menú de opciones inicial -->
  <ng-container *ngIf="estado === 'opciones'">
    <div class="accion-item editar" (click)="seleccionarAccion('editar')">
      <i class="bx bx-edit"></i> Editar cita
    </div>
    <div class="accion-item finalizar" *ngIf="trabajo.extendedProps.finalizada_confirm == 0" (click)="seleccionarAccion('finalizar')">
      <i class="bx bx-check-circle"></i> Finalizar cita
    </div>
    <div class="accion-item reactivar" *ngIf="trabajo.extendedProps.cancelada == 1" (click)="seleccionarAccion('reactivar')">
      <i class="bx bx-revision"></i> Reactivar cita
    </div>
    <div class="accion-item cancelar" *ngIf="trabajo.extendedProps.cancelada != 1 && trabajo.extendedProps.finalizada_confirm == 0" (click)="seleccionarAccion('cancelar')">
      <i class="bx bx-x-circle"></i> Cancelar cita
    </div>
    <div class="accion-item eliminar" (click)="seleccionarAccion('eliminar')">
      <i class="bx bx-trash"></i> Eliminar cita
    </div>
  </ng-container>

  <!-- Formulario de edición -->
  <ng-container *ngIf="estado === 'editar'">
    <form>

     <!-- Reemplazar el campo de cabina con un select solo si hay cabinas -->
     <div class="form-group" *ngIf="tieneCabinas && cabinas && cabinas.length > 0">
          <label>Cabina</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="bx bx-map-pin"></i></span>
            </div>
            <select class="form-control" [(ngModel)]="datosTrabajo.cabina_id" name="cabina_id">
              <option [value]="''">Selecciona una cabina</option>
              <option *ngFor="let cabina of cabinas" [value]="cabina.id">
                {{ cabina.nombre }}
              </option>
            </select>
          </div>
     </div>
     
      <!-- Reemplazar el campo de ubicación con un select solo si hay ubicaciones -->
      <div class="form-group" *ngIf="tieneUbicaciones && ubicaciones && ubicaciones.length > 0">
          <label>Ubicación</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="bx bx-map-pin"></i></span>
            </div>
            <select class="form-control" [(ngModel)]="datosTrabajo.ubicacion_id" name="ubicacion_id">
               <option [ngValue]="null">Selecciona una ubicación</option>
               <option *ngFor="let ubicacion of ubicaciones" [ngValue]="ubicacion.id">
                 {{ ubicacion.nombre }}
               </option>
             </select>  
          </div>
      </div>

      <div class="row">
          <div class="col-md-6">
               <div class="form-group">
                 <label>Artista</label>
                 <div class="input-group">
                   <div class="input-group-prepend">
                     <span class="input-group-text"><i class="bx bx-paint"></i></span>
                   </div>
                   <select class="form-control" [(ngModel)]="datosTrabajo.artista_id" name="artista_id">
                     <option [value]="''">Selecciona un artista</option>
                     <option *ngFor="let artista of artistas" [value]="artista.id">
                       {{ artista.nombre }} {{ artista.apellido1 }}
                     </option>
                   </select>
                 </div>
               </div>
          </div>
          <div class="col-md-6">
               <div class="form-group">
               <label>Fecha</label>
               <div class="input-group">
               <div class="input-group-prepend">
                    <span class="input-group-text"><i class="bx bx-calendar"></i></span>
               </div>
               <input type="date" class="form-control" [(ngModel)]="datosTrabajo.fecha" name="fecha" />
               </div>
               </div>
          </div>
      </div>
      
      
      <div class="row" *ngIf="!(user?.rol_id === 4 && permisosArtista?.ver_totales === 0)">
          <div class="col-md-6">
               <div class="form-group">
                 <label>Precio</label>
                 <div class="input-group">
                   <div class="input-group-prepend">
                     <span class="input-group-text"><i class="bx bx-euro"></i></span>
                   </div>
                   <input type="number" class="form-control" [(ngModel)]="datosTrabajo.precio" name="precio" />
                 </div>
               </div>
          </div>
          <div class="col-md-6">
               <div class="form-group">
               <label>Comisión artista (%)</label>
               <div class="input-group">
               <div class="input-group-prepend">
                    <span class="input-group-text"><i class="mdi mdi-percent-outline"></i></span>
               </div>
               <input type="number" class="form-control" [(ngModel)]="datosTrabajo.comision_artista" name="comision_artista" [disabled]="(user?.rol_id === 4 && permisosArtista?.cambiar_comision === 0)"/>
               </div>
               </div>
          </div>
      </div>
      
      <div class="row">
          <div class="col-md-6">
               <div class="form-group">
                 <label>Hora inicio</label>
                 <div class="input-group">
                   <div class="input-group-prepend">
                     <span class="input-group-text"><i class="bx bx-time"></i></span>
                   </div>
                   <input type="time" class="form-control" [(ngModel)]="datosTrabajo.horai" name="horai" />
                 </div>
               </div>
          </div>
          <div class="col-md-6">
               <div class="form-group">
               <label>Hora fin</label>
               <div class="input-group">
               <div class="input-group-prepend">
                    <span class="input-group-text"><i class="bx bx-time-five"></i></span>
               </div>
               <input type="time" class="form-control" [(ngModel)]="datosTrabajo.horaf" name="horaf" />
               </div>
               </div>
          </div>
      </div>
      
      <div class="form-group">
        <label>Descripción</label>
        <textarea class="form-control" [(ngModel)]="datosTrabajo.descripcion" name="descripcion" rows="3"></textarea>
      </div>

      <div class="row">
          <div class="col-md-12">
               <div class="form-check mb-2">
                    <input type="checkbox" class="form-check-input" id="confirmacionSenal" [(ngModel)]="mostrarCamposSenal" name="confirmacionSenal">
                    <label class="form-check-label" for="confirmacionSenal">Cita confirmada con señal</label>
               </div>
          </div>
      </div>

      <div class="row" *ngIf="mostrarCamposSenal">
        <div class="col-md-6">
          <div class="form-group">
            <label>Señal</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="bx bx-money"></i></span>
              </div>
              <input type="number" class="form-control" [(ngModel)]="datosTrabajo.senal" name="senal" />
            </div>
          </div>
        </div>
          <div class="col-md-6">
               <div class="form-group">
                    <label>Forma de pago señal</label>
                    <select class="form-control" [(ngModel)]="datosTrabajo.forma_pagosenal" name="forma_pagosenal">
                         <option value="">Seleccionar forma de pago</option>
                         <option value="efectivo">Efectivo</option>
                         <option value="tarjeta">Tarjeta</option>
                         <option value="transferencia">Transferencia</option>
                         <option value="bizum">Bizum</option>
                         <option value="paypal">PayPal</option>
                         <option value="yappy">Yappy</option>
                    </select>
               </div>
          </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Fecha de pago señal</label>
            <input type="date" class="form-control" [(ngModel)]="datosTrabajo.fecha_pagosenal" name="fecha_pagosenal" />
          </div>
        </div>
      </div>
      
    </form>
  </ng-container>

  <!-- Confirmaciones -->
     <ng-container *ngIf="estado === 'confirmar-cancelar'">
          <!-- Formulario para agregar información adicional en la cancelación -->
          <form>
               <div class="custom-control custom-switch mb-2">
                    <input type="checkbox" class="custom-control-input" id="noSePresento" [(ngModel)]="datosTrabajo.noSePresento" name="noSePresento">
                    <label class="custom-control-label" for="noSePresento">El cliente no se ha presentado</label>
               </div>
               <p><small><i class="bx bx-error me-1"></i> Marcando esta opción, se marcará el cliente como "Conflictivo" por Ghosting</small></p>
               <div class="form-group">
                    <label>Motivo de cancelación</label>
                    <textarea class="form-control" [(ngModel)]="datosTrabajo.motivosCancelacion" name="motivosCancelacion" rows="2"></textarea>
               </div>
          </form>
     </ng-container>
   
  
  <ng-container *ngIf="estado === 'confirmar-reactivar'">
    <div class="confirmacion-container reactivar">
      <div class="alert alert-info">
        La cita volverá a estar activa en el calendario.
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="estado === 'confirmar-finalizar'">
     <div class="confirmacion-container finalizar">
          <p>Confirma los datos de cierre de la cita.</p>
     </div>
     <!-- [Formulario de finalización ya existente] -->
     <form>
       <div class="row mb-3" *ngIf="!(user?.rol_id === 4 && permisosArtista?.ver_totales === 0)">
         <div class="col-md-6">
           <div class="form-group mb-0">
             <label>Precio final</label>
             <div class="input-group">
               <div class="input-group-prepend">
                 <span class="input-group-text"><i class="bx bx-euro"></i></span>
               </div>
               <input type="number" class="form-control" [(ngModel)]="datosTrabajo.precio" name="precio" />
             </div>
           </div>
         </div>
         <div class="col-md-6">
           <div class="form-group mb-0">
             <label>Comisión artista (%)</label>
             <div class="input-group">
               <div class="input-group-prepend">
                 <span class="input-group-text"><i class="mdi mdi-percent-outline"></i></span>
               </div>
               <input type="number" class="form-control" [(ngModel)]="datosTrabajo.comision_artista" name="comision_artista" [disabled]="(user?.rol_id === 4 && permisosArtista?.cambiar_comision === 0)" />
             </div>
           </div>
         </div>
       </div>

       <!-- Fila 2: Hora inicio y Hora fin -->
       <div class="row mb-3">
         <div class="col-md-6">
           <div class="form-group mb-0">
             <label>Hora inicio</label>
             <div class="input-group">
               <div class="input-group-prepend">
                 <span class="input-group-text"><i class="bx bx-time"></i></span>
               </div>
               <input type="time" class="form-control" [(ngModel)]="datosTrabajo.horai" name="horai" />
             </div>
           </div>
         </div>
         <div class="col-md-6">
           <div class="form-group mb-0">
             <label>Hora fin</label>
             <div class="input-group">
               <div class="input-group-prepend">
                 <span class="input-group-text"><i class="bx bx-time-five"></i></span>
               </div>
               <input type="time" class="form-control" [(ngModel)]="datosTrabajo.horaf" name="horaf" />
             </div>
           </div>
         </div>
       </div>

       <!-- Fila 3: Forma de pago -->
       <div class="row">
         <div class="col-12">
           <div class="form-group mb-0">
             <label>Forma de pago</label>
             <div class="input-group">
               <div class="input-group-prepend">
                 <span class="input-group-text"><i class="bx bx-credit-card"></i></span>
               </div>
               <select class="form-control" [(ngModel)]="datosTrabajo.formapago" name="formapago">
                 <option value="">Seleccionar forma de pago</option>
                 <option value="efectivo">Efectivo</option>
                 <option value="tarjeta">Tarjeta</option>
                 <option value="transferencia">Transferencia</option>
                 <option value="bizum">Bizum</option>
                 <option value="paypal">PayPal</option>
                 <option value="yappy">Yappy</option>
               </select>
             </div>
           </div>
         </div>
       </div>
     </form>
   </ng-container>
   
  
  <ng-container *ngIf="estado === 'confirmar-eliminar'">
    <div class="confirmacion-container eliminar">
      <p><i class="bx bx-trash align-middle"></i> ¿Seguro que quieres eliminar esta cita?</p>
      <div class="alert alert-danger">
        Esta acción es irreversible y la cita será eliminada de forma definitiva.
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <!-- Menú inicial -->
  <ng-container *ngIf="estado === 'opciones'">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
      <i class="bx bx-x mr-1"></i> Cerrar
    </button>
  </ng-container>
  
  <!-- Footer del formulario de edición -->
  <ng-container *ngIf="estado === 'editar'">
    <button type="button" class="btn btn-success" (click)="guardarEdicion()">
      <i class="bx bx-save mr-1"></i> Guardar
    </button>
    <button type="button" class="btn btn-danger" (click)="cancelarEdicion()">
      <i class="bx bx-x mr-1"></i> Cancelar
    </button>
  </ng-container>
  
  <!-- Footer de confirmaciones -->
  <ng-container *ngIf="estado === 'confirmar-cancelar'">
     <button type="button" class="btn btn-danger" (click)="guardarCancelacion()">
       <i class="bx bx-check mr-1"></i> Sí, cancelar cita
     </button>
     <button type="button" class="btn btn-secondary" (click)="respuestaConfirmacion(false)">
       <i class="bx bx-x mr-1"></i> No
     </button>
   </ng-container>
   
  
  <ng-container *ngIf="estado === 'confirmar-reactivar'">
    <button type="button" class="btn btn-primary" (click)="guardarReactivacion()">
      <i class="bx bx-check mr-1"></i> Sí, reactivar cita
    </button>
    <button type="button" class="btn btn-secondary" (click)="respuestaConfirmacion(false)">
      <i class="bx bx-x mr-1"></i> No
    </button>
  </ng-container>
  
  <ng-container *ngIf="estado === 'confirmar-finalizar'">
     <button type="button" class="btn btn-primary" (click)="guardarFinalizacion()">
       <i class="bx bx-check mr-1"></i> Sí, finalizar cita
     </button>
     <button type="button" class="btn btn-secondary" (click)="respuestaConfirmacion(false)">
       <i class="bx bx-x mr-1"></i> No
     </button>
   </ng-container>
   
  
  <ng-container *ngIf="estado === 'confirmar-eliminar'">
    <button type="button" class="btn btn-danger" (click)="respuestaConfirmacion(true)">
      <i class="bx bx-trash mr-1"></i> Sí, eliminar cita
    </button>
    <button type="button" class="btn btn-secondary" (click)="respuestaConfirmacion(false)">
      <i class="bx bx-x mr-1"></i> No
    </button>
  </ng-container>
</div>
   